import React, { useContext, useState, useEffect } from "react";
import Web3 from "web3";
import { motion } from "framer-motion";
import "./GlassHeader.scss";
import { TokenMetadataContext } from "./contexts/TokenMetadataContext.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faStore } from "@fortawesome/free-solid-svg-icons";
import { connectWallet } from "./utils/walletUtils.js";

import GlassTabBar from "./GlassTabBar.js"
const GlassHeader = ({ tabsData, paginate, activeTab }) => {
   const { isWalletConnected, accountAddress } =
      useContext(TokenMetadataContext);
   const [balance, setBalance] = useState("0.00");
   const [points, setPoints] = useState(0);
   const [web3, setWeb3] = useState(null);

   useEffect(() => {
      if (window.ethereum) {
         const newWeb3 = new Web3(window.ethereum);
         setWeb3(newWeb3);
      } else {
         console.error("MetaMask not available");
      }
   }, []);

   useEffect(() => {
      if (isWalletConnected && accountAddress && web3) {
         web3.eth
            .getBalance(accountAddress)
            .then((bal) => {
               setBalance(Number(web3.utils.fromWei(bal, "ether")).toFixed(2));
               return null; // Added to satisfy eslint-plugin-promise
            })
            .catch((err) => {
               console.error("Error fetching balance:", err);
               throw err; // Properly throw an error
            });
      } else {
         setBalance("0.00");
         setPoints(0);
      }
   }, [isWalletConnected, accountAddress, web3]);

   useEffect(() => {
      const fetchBalance = () => {
         if (isWalletConnected && accountAddress && web3) {
            return web3.eth
               .getBalance(accountAddress)
               .then((bal) => {
                  setBalance(
                     Number(web3.utils.fromWei(bal, "ether")).toFixed(2)
                  );
                  return null; // Explicitly return null to satisfy the ESLint rule
               })
               .catch((err) => {
                  console.error("Error fetching balance:", err);
                  throw err; // Properly throw an error
               });
         } else {
            setBalance("0.00");
            setPoints(0);
            return Promise.resolve(); // Return a resolved promise for the 'else' path
         }
      };

      fetchBalance();

      const intervalId = setInterval(fetchBalance, 10000); // Fetch balance every 10 seconds

      return () => clearInterval(intervalId); // Clear interval on component unmount
   }, [isWalletConnected, accountAddress, web3]);

   const handleConnectClick = () => {
      if (window.ethereum) {
         window.ethereum
            .request({ method: "eth_requestAccounts" })
            .then((accounts) => {
               if (accounts.length > 0) {
                  connectWallet(accounts[0]);
               }
               return null; // Added to satisfy eslint-plugin-promise
            })
            .catch((err) => {
               console.error("Error connecting wallet:", err);
               throw err; // Properly throw an error
            });
      } else {
         console.error("MetaMask not available");
      }
   };

   const displayAddress = isWalletConnected
      ? accountAddress.slice(0, 8)
      : "Connect";

   const headerVariants = {
      hidden: { opacity: 0 },
      visible: { opacity: 1, transition: { duration: 0.5 } },
   };

   return (
      <motion.div
         className="GH-GlassHeader"
         initial="hidden"
         animate="visible"
         variants={headerVariants}
      >
         <div className="GH-leftIcons">
            <a
               href="https://twitter.com"
               className="GH-iconLink"
               target="_blank"
               rel="noopener noreferrer"
            >
               <FontAwesomeIcon icon={faXTwitter} />
            </a>
            <div className="GH-divider" />
            <a
               href="https://discord.com"
               className="GH-iconLink"
               target="_blank"
               rel="noopener noreferrer"
            >
               <FontAwesomeIcon icon={faDiscord} />
            </a>
            <div className="GH-divider" />
            <a
               href="https://marketplace.example.com"
               className="GH-iconLink"
               target="_blank"
               rel="noopener noreferrer"
            >
               <FontAwesomeIcon icon={faStore} />
            </a>
         </div>
         <div className="GH-centerContent">
            <GlassTabBar
               tabsData={tabsData}
               onTabClick={paginate}
               activeTab={activeTab}
            />
         </div>
         <div className="GH-walletInfo">
            <div className="GH-section">
               <span>{points} PTS</span>
            </div>
            <div className="GH-divider" />
            <div className="GH-section">
               <span>{balance} ETH</span>
            </div>
            <div className="GH-divider" />
            <div
               className={`GH-section ${!isWalletConnected && "GH-connect"}`}
               onClick={handleConnectClick}
            >
               <span>{displayAddress}</span>
            </div>
         </div>
      </motion.div>
   );
};

export default GlassHeader;
