import React, { useState, useRef, useEffect, useContext } from "react";
import "./ItemsDisplay.scss";
import { AnimatePresence, motion } from "framer-motion"; // Importing motion

import CardComponent from "./CardComponent";
import ItemsDisplaySidebar from "./ItemsDisplaySidebar";
import { TokenMetadataContext } from "./contexts/TokenMetadataContext"; // Update the import path accordingly
import Counter from "./Counter";
const ItemsDisplay = ({
   items,
   setSelectedId,
   heading,
   cardSizes,
   children,
   isLoading,
   selectedId,
   isSidebarEnabled = true,
   showWithoutWallet = false,
}) => {
   const [displayedItems, setDisplayedItems] = useState([]);
   const [filteredItems, setFilteredItems] = useState([]);
   const [filters, setFilters] = useState({});
   const loadMoreRef = useRef(null);
   const { loadingProgress, isWalletConnected } =
      useContext(TokenMetadataContext); // Using the context

   const fadeInVariant = {
      hidden: { opacity: 0 },
      visible: { opacity: 1 },
   };

   const connectWallet = async () => {
      if (window.ethereum) {
         try {
            await window.ethereum.request({ method: "eth_requestAccounts" });
         } catch (error) {
            console.error("Failed to connect wallet", error);
         }
      } else {
         alert(
            "MetaMask is not installed. Please install it to use this feature."
         );
      }
   };

   // Apply filters to items
   useEffect(() => {
      const newFilteredItems = items.filter((item) => {
         return Object.entries(filters).every(([traitType, values]) => {
            const filterValues = Object.entries(values)
               .filter(([_, isSelected]) => isSelected)
               .map(([value]) => value);
            if (filterValues.length === 0) return true;

            return item.attributes.some((attribute) => {
               return (
                  attribute.trait_type === traitType &&
                  filterValues.includes(attribute.value)
               );
            });
         });
      });

      setFilteredItems(newFilteredItems);
      setDisplayedItems(newFilteredItems.slice(0, 45));
   }, [filters, items]);

   // Load more items logic
   const loadMoreItems = () => {
      const nextItems = filteredItems.slice(
         displayedItems.length,
         displayedItems.length + 10
      );

      setDisplayedItems((prevItems) => {
         // Filter out duplicates based on id
         const updatedItems = nextItems.filter(
            (nextItem) =>
               !prevItems.some((prevItem) => prevItem.id === nextItem.id)
         );

         return [...prevItems, ...updatedItems];
      });
   };

   // Intersection Observer setup for lazy loading
   useEffect(() => {
      const observer = new IntersectionObserver(
         (entries) => {
            const firstEntry = entries[0];
            if (
               firstEntry.isIntersecting &&
               displayedItems.length < filteredItems.length
            ) {
               loadMoreItems();
            }
         },
         { threshold: 0.01 }
      );

      const loadMoreDiv = loadMoreRef.current;
      if (loadMoreDiv) {
         observer.observe(loadMoreDiv);
      }

      return () => {
         if (loadMoreDiv) {
            observer.unobserve(loadMoreDiv);
         }
      };
   }, [displayedItems, filteredItems.length]);

   const traitTypes = items.reduce((acc, item) => {
      item.attributes.forEach((attr) => {
         const { trait_type, value } = attr;
         if (!acc[trait_type]) acc[trait_type] = {};
         if (!acc[trait_type][value]) {
            acc[trait_type][value] = 0;
         }
         acc[trait_type][value]++;
      });
      return acc;
   }, {});

   // Function to update filters
   const updateFilters = (traitType, value, isChecked) => {
      setFilters((prevFilters) => {
         const updatedFilters = { ...prevFilters };
         if (!updatedFilters[traitType]) {
            updatedFilters[traitType] = {};
         }
         updatedFilters[traitType][value] = isChecked;

         // Remove the traitType entirely if no filter is active for it
         if (
            Object.values(updatedFilters[traitType]).every(
               (selected) => !selected
            )
         ) {
            delete updatedFilters[traitType];
         }

         return updatedFilters;
      });
   };

   const contentToShow = () => {
      if (isLoading) {
         return <Counter value={loadingProgress.toFixed(0)} maxLength={3} />;
      } else if (displayedItems.length > 0) {
         return displayedItems.map((item) => (
            <motion.div
               key={item.id}
               initial="hidden"
               animate="visible"
               variants={fadeInVariant}
               transition={{ duration: 0.5 }}
            >
               <CardComponent
                  textBandTop={item.textBandTop}
                  textBandBottom={item.textBandBottom || item.name}
                  imageSrc={item.image}
                  onClick={() => setSelectedId(item.id)}
                  sizes={cardSizes}
               />
            </motion.div>
         ));
      } else {
         return <p>No tokens owned for this contract</p>;
      }
   };

   return (
      <div className="Items-Display-Wrapper">
         {isSidebarEnabled && (
            <ItemsDisplaySidebar
               traitTypes={traitTypes}
               filters={filters}
               updateFilters={updateFilters}
            />
         )}
         <div className="Items-Display-Container">
            <div className="Item-Display-Title">
               <motion.h1
                  animate={{ opacity: selectedId ? 0 : 1 }}
                  transition={{ duration: 0.5 }}
               >
                  <span>{heading}</span>{" "}
               </motion.h1>
            </div>
            {isWalletConnected || showWithoutWallet ? (
               <>
                  <div className="Inventory-Tabs-Wrapper">{children}</div>
                  <div className="Items-Display-Inner">
                     {contentToShow()}
                     <div ref={loadMoreRef} className="Load-More-Ref" />
                  </div>
               </>
            ) : (
               <div className="Connect-Wallet-Wrapper">
                  <button
                     onClick={connectWallet}
                     className="connect-wallet-button"
                  >
                     Connect Wallet
                  </button>
               </div>
            )}
         </div>
      </div>
   );
};

export default ItemsDisplay;
