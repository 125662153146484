import React, { createContext, useState, useEffect } from "react";
import Web3 from "web3";
import { contracts } from "../static/contractData";
import TokenMetadataDB, {
   addTokenMetadata,
   getTokenMetadata,
} from "../databases/TokenMetadataDB";
import { getAccountAddress } from "../utils/walletUtils";
import { fetchOwnedTokens } from "../hooks/useTokensOfOwner";

const TokenMetadataContext = createContext();

function TokenMetadataProvider({ children }) {
   const [ids, setIds] = useState({});
   const [tokenMetadata, setTokenMetadata] = useState({});
   const [isLoading, setIsLoading] = useState(false);
   const [isWalletConnected, setIsWalletConnected] = useState(false);
   const [accountAddress, setAccountAddress] = useState(null);
   const [loadingProgress, setLoadingProgress] = useState(0);
   const [web3, setWeb3] = useState(null);
   const [currentAbortController, setCurrentAbortController] = useState(null);

   // useEffect hook to initialize web3
   useEffect(() => {
      if (window.ethereum) {
         setWeb3(new Web3(window.ethereum));
      }
   }, []);

   const refreshTokenMetadata = async (contractAddress, ownedIds, signal) => {
      // Find contract details
      const contract = contracts.find((c) => c.address === contractAddress);
      if (!contract) throw new Error("Contract not found");

      // Create contract instance
      const contractInstance = new web3.eth.Contract(
         contract.abi,
         contract.address
      );

      // Fetch base URI
      const baseURI = await contractInstance.methods.getBaseURI().call();

      // Iterate and store metadata
      const metadata = [];
      for (const id of ownedIds) {
         // Check if the operation has been aborted
         if (signal.aborted) {
            console.log("Operation aborted");
            return; // Abort the process if the signal is aborted
         }

         await fetchAndStoreMetadata(contract.address, baseURI, id, signal);
         const tokenMetadata = await getTokenMetadata(contract.address, id);

         // Check again after the async operation
         if (signal.aborted) {
            console.log("Operation aborted");
            return; // Abort the process if the signal is aborted
         }

         metadata.push(tokenMetadata);
         updateProgress(ownedIds.length, metadata.length);
      }

      setIds((prev) => ({ ...prev, [contractAddress]: ownedIds }));
      setTokenMetadata((prev) => ({ ...prev, [contractAddress]: metadata }));
      return metadata;
   };

   const fetchAndStoreMetadata = async (
      contractAddress,
      baseURI,
      id,
      signal
   ) => {
      const compositeKey = [contractAddress, id];
      const existingToken = await TokenMetadataDB.tokens.get(compositeKey);
      if (!existingToken) {
         const metadataUrl = baseURI + id;
         const response = await fetch(metadataUrl, { signal });
         console.log("response", response);
         const metadata = await response.json();
         await addTokenMetadata({
            contractAddress: contractAddress,
            id: id,
            collection: metadata.collection,
            name: metadata.name,
            image: metadata.image,
            external_url: metadata.external_url,
            attributes: metadata.attributes,
         });
      }
   };

   const updateProgress = (total, completed) => {
      const progress = Math.floor((completed / total) * 100);
      if (Math.abs(progress - loadingProgress) >= 1) {
         setLoadingProgress(progress);
      }
   };

   const fetchOwnedTokenMetadata = async (contractAddress) => {
      if (currentAbortController) {
         currentAbortController.abort();
      }
      const newAbortController = new AbortController();
      setCurrentAbortController(newAbortController);

      try {
         const accountAddress = await getAccountAddress();
         const matchingContract = contracts.find(
            (contract) => contract.address === contractAddress
         );

         if (!matchingContract) {
            throw new Error("Contract not found");
         }

         // Create contract instance using web3js
         const contractInstance = new web3.eth.Contract(
            matchingContract.abi,
            matchingContract.address
         );
         console.log(
            "fetching owned tokens with contract address",
            contractAddress
         );
         const ownedIds = await fetchOwnedTokens(
            accountAddress,
            contractInstance
         );
         console.log("owned token numbers", ownedIds);
         const storedIds = ids[contractAddress] || [];

         const isMetadataCurrent =
            ownedIds.length === storedIds.length &&
            ownedIds.every((id, index) => id === storedIds[index]);

         if (!isMetadataCurrent) {
            return await refreshTokenMetadata(
               contractAddress,
               ownedIds,
               newAbortController.signal
            );
         }

         return tokenMetadata[contractAddress] || [];
      } catch (error) {
         if (error.name !== "AbortError") {
            console.error("Error fetching owned token metadata:", error);
            throw error;
         }
      } finally {
         if (newAbortController === currentAbortController) {
            setCurrentAbortController(null);
         }
      }
   };

   const handleAccountsChanged = async (accounts) => {
      if (accounts.length > 0) {
         const address = accounts[0];
         setAccountAddress(address);
         setIsWalletConnected(true);
      } else {
         resetState();
      }
   };

   const resetState = () => {
      setAccountAddress(null);
      setIsWalletConnected(false);
      setIds({});
      setTokenMetadata({});
      setLoadingProgress(0);
      if (currentAbortController) {
         currentAbortController.abort();
      }
   };

   const handleDisconnect = () => {
      setAccountAddress(null);
      setIsWalletConnected(false);
      setIds({});
   };

   useEffect(() => {
      const checkWalletConnection = async () => {
         if (window.ethereum) {
            const web3 = new Web3(window.ethereum);

            try {
               const accounts = await web3.eth.getAccounts();
               if (accounts.length > 0) {
                  await handleAccountsChanged(accounts);
               }
            } catch (error) {
               console.error("Error checking wallet connection:", error);
            }
         }
      };

      checkWalletConnection();

      if (window.ethereum) {
         window.ethereum.on("accountsChanged", handleAccountsChanged);
         window.ethereum.on("disconnect", handleDisconnect);

         return () => {
            window.ethereum.removeListener(
               "accountsChanged",
               handleAccountsChanged
            );
            window.ethereum.removeListener("disconnect", handleDisconnect);
         };
      }
   }, []);

   return (
      <TokenMetadataContext.Provider
         value={{
            ids,
            tokenMetadata,
            isLoading,
            loadingProgress,
            isWalletConnected,
            accountAddress,
            getTokenMetadata,
            fetchOwnedTokens,
            fetchOwnedTokenMetadata,
         }}
      >
         {children}
      </TokenMetadataContext.Provider>
   );
}

export { TokenMetadataContext, TokenMetadataProvider };
