import React, { useContext, useEffect } from "react";
import GlassTab from "./GlassTab";
import "./GlassTabBar.scss";
import { motion, useAnimation } from "framer-motion";
import { AnimationContext } from "./AnimationContext";

const GlassTabBar = ({ tabsData, onTabClick, activeTab }) => {
  const controls = useAnimation();
  const { provideAnimationControls, isSidebarExpanded } =
    useContext(AnimationContext);

  useEffect(() => {
    provideAnimationControls(controls);
    controls.start("visible");
  }, [controls, provideAnimationControls]);

  const tabBarVariants = {
    hidden: {
      opacity: 0,
      y: 50,
      transition: { duration: 0.3, type: "spring", stiffness: 100 },
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.3, type: "spring", stiffness: 100 },
    },
  };

  // Error handling and logging
  if (!Array.isArray(tabsData)) {
    console.warn("GlassTabBar: 'tabsData' is undefined or not an array.", tabsData);
    return null; // Or render a fallback UI
  }

  return (
    <div className="GlassTabBarContainer">
      <motion.div
        className="GlassTabBar"
        variants={tabBarVariants}
        initial="hidden"
        animate={controls}
      >
        {tabsData.map((tab, index) => (
          <GlassTab
            key={tab.title}
            title={tab.title}
            subtitle={tab.subtitle}
            icon={tab.icon}
            isActive={activeTab === index}
            isFirstTab={index === 0} // Additional prop to identify the first tab
            onClick={() => onTabClick(index)}
            showText={!isSidebarExpanded}
          />
        ))}
      </motion.div>
    </div>
  );
};

export default GlassTabBar;
