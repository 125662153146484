import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { debounce } from 'lodash';
import './Homepage.scss';
import HomepageLogoSVG from './assets/svgs/HomepageLogo';
import HomepageItemsDisplay from './HomepageItemsDisplay';
import HomepageWhitelist from './HomepageWhitelist';

import CardComponent from './CardComponent';
import TraitsBox from './TraitsBox';
import HomepageFAQ from './HomepageFAQ';

import img0 from './assets/imgs/0.png';
import img1 from './assets/imgs/1.png';
import img2 from './assets/imgs/2.png';
import img3 from './assets/imgs/3.png';
import img4 from './assets/imgs/4.png';
import img5 from './assets/imgs/5.png';
import img6 from './assets/imgs/6.png';
import img7 from './assets/imgs/7.png';
import img8 from './assets/imgs/8.png';
import img9 from './assets/imgs/9.png';
import img10 from './assets/imgs/10.png';
import img11 from './assets/imgs/11.png';
import img12 from './assets/imgs/12.png';
import img13 from './assets/imgs/13.png';
import img14 from './assets/imgs/14.png';
import img15 from './assets/imgs/15.png';
import img16 from './assets/imgs/16.png';
import img17 from './assets/imgs/17.png';
import img18 from './assets/imgs/18.png';
import img19 from './assets/imgs/19.png';
import img20 from './assets/imgs/20.png';
import img21 from './assets/imgs/21.png';
import img22 from './assets/imgs/22.png';
import img23 from './assets/imgs/23.png';
import img24 from './assets/imgs/24.png';
import img25 from './assets/imgs/25.png';
import img26 from './assets/imgs/26.png';
import img27 from './assets/imgs/27.png';
import img28 from './assets/imgs/28.png';
import img29 from './assets/imgs/29.png';
import img30 from './assets/imgs/30.png';
import img31 from './assets/imgs/31.png';
import img32 from './assets/imgs/32.png';
import img33 from './assets/imgs/33.png';
import img34 from './assets/imgs/34.png';
import img35 from './assets/imgs/35.png';
import img36 from './assets/imgs/36.png';
import img37 from './assets/imgs/37.png';
import img38 from './assets/imgs/38.png';
import img39 from './assets/imgs/39.png';
import img40 from './assets/imgs/40.png';
import img41 from './assets/imgs/41.png';
import img42 from './assets/imgs/42.png';
import img43 from './assets/imgs/43.png';
import img44 from './assets/imgs/44.png';
import img45 from './assets/imgs/45.png';
import img46 from './assets/imgs/46.png';
import img47 from './assets/imgs/47.png';
import img48 from './assets/imgs/48.png';
import img49 from './assets/imgs/49.png';
import img50 from './assets/imgs/50.png';
import img51 from './assets/imgs/51.png';
import img52 from './assets/imgs/52.png';
import img53 from './assets/imgs/53.png';
import img54 from './assets/imgs/54.png';
import img55 from './assets/imgs/55.png';
import img56 from './assets/imgs/56.png';
import img57 from './assets/imgs/57.png';
import img58 from './assets/imgs/58.png';
import img59 from './assets/imgs/59.png';
import img60 from './assets/imgs/60.png';
import img61 from './assets/imgs/61.png';
import img62 from './assets/imgs/62.png';
import img63 from './assets/imgs/63.png';
import img64 from './assets/imgs/64.png';
import img65 from './assets/imgs/65.png';
import img66 from './assets/imgs/66.png';
import img67 from './assets/imgs/67.png';
import img68 from './assets/imgs/68.png';
import img69 from './assets/imgs/69.png';


// Map images for easier access
const imageMap = {
    '0': img0,
    '1': img1,
    '2': img2,
    '3': img3,
    '4': img4,
    '5': img5,
    '6': img6,
    '7': img7,
    '8': img8,
    '9': img9,
    '10': img10,
    '11': img11,
    '12': img12,
    '13': img13,
    '14': img14,
    '15': img15,
    '16': img16,
    '17': img17,
    '18': img18,
    '19': img19,
    '20': img20,
    '21': img21,
    '22': img22,
    '23': img23,
    '24': img24,
    '25': img25,
    '26': img26,
    '27': img27,
    '28': img28,
    '29': img29,
    '30': img30,
    '31': img31,
    '32': img32,
    '33': img33,
    '34': img34,
    '35': img35,
    '36': img36,
    '37': img37,
    '38': img38,
    '39': img39,
    '40': img40,
    '41': img41,
    '42': img42,
    '43': img43,
    '44': img44,
    '45': img45,
    '46': img46,
    '47': img47,
    '48': img48,
    '49': img49,
    '50': img50,
    '51': img51,
    '52': img52,
    '53': img53,
    '54': img54,
    '55': img55,
    '56': img56,
    '57': img57,
    '58': img58,
    '59': img59,
    '60': img60,
    '61': img61,
    '62': img62,
    '63': img63,
    '64': img64,
    '65': img65,
    '66': img66,
    '67': img67,
    '68': img68,
    '69': img69,

};

function generateItems(numberOfItems) {
    // Sample list of human female names
    const femaleNames = [
        "Emma", "Olivia", "Ava", "Isabella", "Sophia",
        "Charlotte", "Mia", "Amelia", "Harper", "Evelyn",
        // Add more names as necessary
    ];

    const baseItems = [
        {
            idBase: 'collect',
            nameBase: 'Collector', // Adjusted for dynamic naming
            textBandTopBase: 'Top Text for Collect',
            textBandBottomBase: 'Collect',
            imageKey: 'collect', // Use a key for looking up in imageMap
            attributes: [
                { "trait_type": 'Rank', value: 'Apprentice' }, { "trait_type": 'Discipline', value: 'Light' }
            ],
        },
        {
            idBase: 'compete',
            nameBase: 'Competitor', // Adjusted for dynamic naming
            textBandTopBase: 'Top Text for Compete',
            textBandBottomBase: 'Compete',
            imageKey: 'compete', // Use a key for looking up in imageMap
            attributes: [
                { "trait_type": 'Rank', value: 'Scholar' }, { "trait_type": 'Discipline', value: 'Water' }
            ],
        },
        {
            idBase: 'conquer',
            nameBase: 'Conqueror', // Adjusted for dynamic naming
            textBandTopBase: 'Top Text for Conquer',
            textBandBottomBase: 'Conquer',
            imageKey: 'conquer', // Use a key for looking up in imageMap
            attributes: [
                { "trait_type": 'Rank', value: 'Scholar' }, { "trait_type": 'Discipline', value: 'Fire' }
            ],
        },
    ];

    let items = [];
    for (let i = 0; i < numberOfItems; i++) {
        const baseItem = baseItems[i % baseItems.length];
        const nameIndex = i % femaleNames.length;
        const imageName = `${i % 70}`; // Ensure this matches the imageMap keys
        const item = {
            id: `${baseItem.idBase}-${i + 1}`,
            name: `${femaleNames[nameIndex]}`,
            textBandTop: `${baseItem.textBandTopBase} ${i + 1}`,
            textBandBottom: `${baseItem.textBandBottomBase} ${i + 1}`,
            image: imageMap[imageName], // Lookup image from imageMap
            attributes: baseItem.attributes
        };
        items.push(item);
    }
    return items;
}

const items = generateItems(70);

const cardSizes = {
    small: { width: "128px", height: "192px" },
    medium: { width: "128px", height: "192px" },
    large: { width: "128px", height: "192px" },
    xLarge: { width: "128px", height: "192px" },
    xxLarge: { width: "128px", height: "192px" },
};




function Homepage() {
    const [selectedId, setSelectedId] = useState(items[0].id);
    const [selectedTraits, setSelectedTraits] = useState([]);

    const latestHoveredIdRef = useRef(null);
    const hoverTimeoutRef = useRef(null);

    const memoizedSetHoveredId = useCallback((id) => {
        latestHoveredIdRef.current = id; // Set the latest hovered ID
        if (hoverTimeoutRef.current) {
            clearTimeout(hoverTimeoutRef.current); // Clear any existing timeout
        }

        hoverTimeoutRef.current = setTimeout(() => {
            if (latestHoveredIdRef.current === id) {
                setSelectedId(id); // Update only if the ID hasn't changed
            }
        }, 500);
    }, []);

    const memoizedClearHoveredId = useCallback(() => {
        latestHoveredIdRef.current = null; // Clear the latest hovered ID
        if (hoverTimeoutRef.current) {
            clearTimeout(hoverTimeoutRef.current); // Clear the timeout
            hoverTimeoutRef.current = null; // Reset the ref
        }
    }, []);

    // Cleanup the timeout on component unmount
    useEffect(() => {
        return () => {
            if (hoverTimeoutRef.current) {
                clearTimeout(hoverTimeoutRef.current);
            }
        };
    }, []);

    const selectedItem = items.find(item => item.id === selectedId);

    const onTraitClick = useCallback((clickedTrait) => {
        setSelectedTraits(prev => {
            const isActive = prev.find(trait => trait.trait_type === clickedTrait.trait_type && trait.value === clickedTrait.value);
            if (isActive) {
                return prev.filter(trait => trait.trait_type !== clickedTrait.trait_type || trait.value !== clickedTrait.value);
            } else {
                return [...prev, clickedTrait];
            }
        });
    }, []);

    // Filter items based on selectedTraits
    const filteredItems = useMemo(() => {
        // If no traits are selected, return all items
        if (selectedTraits.length === 0) return items;

        return items.filter(item =>
            selectedTraits.every(selectedTrait =>
                item.attributes.some(attr =>
                    attr.trait_type === selectedTrait.trait_type && attr.value === selectedTrait.value
                )

            )

        );
    }, [items, selectedTraits]);

    const memoizedSetSelectedId = useCallback((id) => {
        setSelectedId(id); // Immediately update the selectedId
    }, []);


    return (
        <div className="Homepage-Container">
            <div className="Homepage-Info">
                <div className='Homepage-Card-Container'>
                    <AnimatePresence mode="wait">
                        {selectedItem && (
                            <motion.div
                                key={selectedItem.id}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1, transition: { duration: 1.0 } }}
                                exit={{ opacity: 0, transition: { duration: 0.5 } }}
                                transition={{ duration: 0.63 }}
                            >
                                <div className="Card-Traits-Container">
                                    <h2 className="Item-Name">{selectedItem.name}</h2>
                                    <CardComponent
                                        imageSrc={selectedItem.image}
                                        innerRefCallback={() => { }}
                                        onClick={() => { }}
                                        scaleOnMouseEnter={1.05}
                                        enableRotation={true}
                                        enableScale={true}
                                        margin="8px"
                                        className="CharacterDex-Card"
                                        fadeInImage={true}
                                    />
                                    <div className="Traits-Box-Container">
                                        <TraitsBox
                                            traits={selectedItem.attributes.map(attr => ({
                                                ...attr,
                                                isActive: selectedTraits.some(selectedTrait => selectedTrait.trait_type === attr.trait_type && selectedTrait.value === attr.value),
                                            }))}
                                            onTraitClick={onTraitClick}
                                        />
                                    </div>
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
                <div className='Homepage-FAQ-Container'>
                    <HomepageFAQ />
                </div>
            </div>
            <div className="Homepage-Logo">
                <HomepageLogoSVG />
            </div>
            <div className="Homepage-Slideshow">
                <HomepageItemsDisplay
                    items={filteredItems}
                    setSelectedId={memoizedSetHoveredId}
                    onClick={memoizedSetSelectedId}
                    onHover={memoizedSetHoveredId}
                    onMouseLeave={memoizedClearHoveredId}
                    heading="Explore the Collection"
                    isLoading={false}
                    cardSizes={cardSizes}
                />
            </div>
            <div className="Homepage-Whitelist">
                <HomepageWhitelist />
            </div>
        </div>
    );

}

export default Homepage;