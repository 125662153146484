import { useState, useEffect, useCallback } from "react";
import Web3 from "web3";
import { toast } from "react-toastify";

const useWeb3 = (contractABI, contractAddress, expectedChainId) => {
   const [web3, setWeb3] = useState(null);
   const [isWalletConnected, setIsWalletConnected] = useState(false);
   const [contractInstance, setContractInstance] = useState(null);

   // Initialize Web3
   useEffect(() => {
      if (window.ethereum) {
         const web3Instance = new Web3(window.ethereum);
         setWeb3(web3Instance);
         checkIfWalletIsConnected(web3Instance);
      } else {
         toast.error("Ethereum object not found, please install MetaMask.");
      }
   }, []);

   // Check if wallet is connected
   const checkIfWalletIsConnected = async (web3Instance) => {
      try {
         const accounts = await web3Instance.eth.getAccounts();
         setIsWalletConnected(accounts.length > 0);
      } catch (error) {
         toast.error(
            `Failed to check if wallet is connected: ${error.message}`
         );
      }
   };

   // Connect wallet
   const connectWallet = async () => {
      try {
         await window.ethereum.request({ method: "eth_requestAccounts" });
         setIsWalletConnected(true);
         checkChainId();
      } catch (error) {
         toast.error(`Failed to connect wallet: ${error.message}`);
         setIsWalletConnected(false);
      }
   };

   // Check chain ID
   const checkChainId = useCallback(async () => {
      if (!web3) return;
      try {
         const chainId = await web3.eth.getChainId();
         if (chainId.toString() !== expectedChainId.toString()) {
            toast.warn(
               `Please switch your wallet to chain ID ${expectedChainId}`
            );
         }
      } catch (error) {
         toast.error(`Failed to check chain ID: ${error.message}`);
      }
   }, [web3, expectedChainId]);

   // Create contract instance
   useEffect(() => {
      if (web3 && contractABI && contractAddress && isWalletConnected) {
         const instance = new web3.eth.Contract(contractABI, contractAddress);
         setContractInstance(instance);
      }
   }, [web3, contractABI, contractAddress, isWalletConnected]);

   // Event listeners
   useEffect(() => {
      const handleAccountsChanged = (accounts) => {
         setIsWalletConnected(accounts.length > 0);
         if (accounts.length > 0) checkChainId();
      };

      const handleChainChanged = () => {
         checkIfWalletIsConnected(web3);
      };

      if (window.ethereum) {
         window.ethereum.on("accountsChanged", handleAccountsChanged);
         window.ethereum.on("chainChanged", handleChainChanged);
      }

      return () => {
         if (window.ethereum) {
            window.ethereum.removeListener(
               "accountsChanged",
               handleAccountsChanged
            );
            window.ethereum.removeListener("chainChanged", handleChainChanged);
         }
      };
   }, [web3, checkChainId]);

   return {
      web3,
      isWalletConnected,
      contractInstance,
      connectWallet,
      checkChainId,
   };
};

export default useWeb3;
