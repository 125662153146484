import React from "react";
import "./HomepageItemsDisplay.scss";
import { AnimatePresence, motion } from "framer-motion";
import CardComponent from "./CardComponent";

const HomepageItemsDisplay = ({
    items,
    heading,
    cardSizes,
    onHover,
    onClick,
    onMouseLeave,
    children,
}) => {
    const fadeInVariant = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
    };

    const contentToShow = () => {
        return items.map(item => (
            <motion.div
                key={item.id}
                initial="hidden"
                animate="visible"
                variants={fadeInVariant}
                transition={{ duration: 0.5 }}
            >
                <CardComponent
                    imageSrc={item.image}
                    onClick={() => onClick?.(item.id)}
                    onHover={() => onHover?.(item.id)}
                    onMouseLeave={() => onMouseLeave?.()}
                    sizes={cardSizes}
                />
            </motion.div>
        ));
    };

    return (
        <div className="Homepage-Items-Display-Wrapper">
            <div className="Homepage-Items-Display-Container">
                <div className="Homepage-Item-Display-Title">
                    {/* Changed from motion.h1 to motion.div */}
                    <motion.div animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
                        <span>{heading}</span>
                    </motion.div>
                </div>
                <div className="Homepage-Inventory-Tabs-Wrapper">{children}</div>
                <div className="Homepage-Items-Display-Inner">
                    {contentToShow()}
                </div>
            </div>
        </div>
    );
};

export default HomepageItemsDisplay;
