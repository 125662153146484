import React, { useState, useContext, useEffect, useRef } from "react";
import { motion, AnimatePresence, LayoutGroup } from "framer-motion";
import ItemsDisplay from "./ItemsDisplay";
import { TokenMetadataContext } from "./contexts/TokenMetadataContext";
import { contracts } from "./static/contractData";
import InventoryTabs from "./InventoryTabs";
import GridImageDisplay from "./GridImageDisplay";
import { AnimationContext } from "./AnimationContext";
import InventoryItemInfoAndClaim from "./InventoryItemInfoAndClaim";
import useApi from "./hooks/useApi";

const Inventory = () => {
   const [mergedMetadata, setmergedMetadata] = useState([]);
   const [traitMetadata, setTraitMetadata] = useState([]);
   const [selectedContract, setSelectedContract] = useState([]);
   const [selectedId, setSelectedId] = useState(null);
   const [isLoading, setIsLoading] = useState(false);
   const [error, setError] = useState(null);
   const [claimStatus, setClaimStatus] = useState(null);
   const [statusClaim, setStatusClaim] = useState(null);
   const [pendingClaims, setPendingClaims] = useState(null);
   const [isFetchingClaimStatus, setIsFetchingClaimStatus] = useState(false);
   const selectedIdRef = useRef(null);
   const [renderTrigger, setRenderTrigger] = useState({});
   const { controls } = useContext(AnimationContext);
   const context = useContext(TokenMetadataContext);
   const { sendRequest } = useApi();
   const selectedContractRef = useRef(null);

   const [requestQueue, setRequestQueue] = useState([]);
   const [requestId, setRequestId] = useState(null); // Unique identifier for the current tab
   const [isRequestInProgress, setIsRequestInProgress] = useState(false);
   const [hasMintOccurred, setHasMintOccurred] = useState(false);

   const handleMintAction = () => {
      setHasMintOccurred(true);
   };

   useEffect(() => {
      if (!context) {
         setError(
            "TokenMetadataContext is not available. Ensure you are within its Provider."
         );
      }
   }, [context]);

   const getSelectedItem = () => {
      return mergedMetadata.find((item) => item.id === selectedIdRef.current);
   };

   useEffect(() => {
      const processQueue = async () => {
         if (requestQueue.length === 0 || isRequestInProgress) return;

         setIsRequestInProgress(true);
         const currentRequest = requestQueue[0];

         if (currentRequest.requestId === requestId) {
            if (currentRequest.type === "fetchTokens") {
               await fetchTokensByContract(currentRequest.contract);
            } else if (currentRequest.type === "fetchClaimStatus") {
               setTraitMetadata(currentRequest.metadata);
               await fetchClaimStatus(
                  currentRequest.metadata,
                  currentRequest.contract.address
               );
            }
         }

         setRequestQueue((prevQueue) => prevQueue.slice(1));
         setIsRequestInProgress(false);
      };

      processQueue();
   }, [requestQueue, isRequestInProgress, requestId]);

   const generateRequestId = () => {
      return Date.now().toString(); // Simple unique identifier based on timestamp
   };

   const handleFetchTokensByContract = (contract) => {
      console.log("selected contract", contract);

      // Generate a new requestId for the selected contract
      const newRequestId = generateRequestId();
      setRequestId(newRequestId);

      // Update the selectedContractRef with the new contract
      selectedContractRef.current = contract;

      // Reset states for the new contract
      setmergedMetadata([]);
      setSelectedId(null);
      setTraitMetadata([]);
      setError(null);
      setClaimStatus(null);
      setStatusClaim(null);
      setPendingClaims(null);
      setIsFetchingClaimStatus(false);

      // Add the new request with the requestId to the queue
      setRequestQueue((prevQueue) => [
         ...prevQueue.filter((req) => req.requestId === requestId), // Keep only relevant requests
         { contract, type: "fetchTokens", requestId: newRequestId },
      ]);
   };

   const fetchTokensByContract = async (contract) => {
      setIsLoading(true);
      setSelectedContract(contract);

      try {
         const tokensByContract = await context.fetchOwnedTokenMetadata(
            contract.address
         );

         // Check if the contract is still the currently selected one
         if (selectedContractRef.current !== contract) {
            // The contract has changed, ignore this data
            return;
         }

         // Handle the fetched data
         if (Array.isArray(tokensByContract) && tokensByContract.length > 0) {
            setmergedMetadata(tokensByContract);
            if (contract.id === "genesis_pass") {
               setRequestQueue((prevQueue) => [
                  ...prevQueue,
                  {
                     type: "fetchClaimStatus",
                     metadata: tokensByContract,
                     requestId,
                     contract: selectedContractRef.current, // Pass the current contract
                  },
               ]);
            }
         } else {
            setError("No items found in this contract.");
         }
      } catch (error) {
         console.error("Error fetching tokens by contract:", error);
         setError("Failed to fetch tokens. Please try again later.");
      } finally {
         setIsLoading(false);
      }
   };

   const fetchClaimStatus = async (metadata, contractForStatus) => {
      console.log("contract for statys", contractForStatus);
      const endpoint =
         "http://localhost:8080/api/public/genesis-passes/claim-status";
      setIsFetchingClaimStatus(true);
      try {
         const response = await sendRequest("GET", endpoint, {});
         console.log(response);
         if (response) {
            const statusMap = response.claimStatus.reduce((acc, item) => {
               acc[item.id] = item.attributes;
               return acc;
            }, {});

            const mergedMetadata = metadata.map((item) => {
               const itemClaims = statusMap[item.id] || [];
               return {
                  ...item,
                  attributes: [...item.attributes, ...itemClaims],
               };
            });
            if (selectedContractRef.current.address !== contractForStatus) {
               return;
            }

            setmergedMetadata(mergedMetadata);
            setClaimStatus(statusMap);
            setStatusClaim(response.statusClaim);
         }

         if (response.pendingClaims) {
            console.log("pending claims", response.pendingClaims);
            setPendingClaims(response.pendingClaims);
         } else {
            console.log("No pending claims");
            setPendingClaims({});
         }
      } catch (error) {
         console.error("Error fetching claim status:", error);
      } finally {
         setIsFetchingClaimStatus(false);
      }
   };

   const setSelectedIdWithAnimation = (id) => {
      selectedIdRef.current = id;
      setSelectedId(id);
      setRenderTrigger({});
      if (id && controls) {
         controls.start("hidden");
      }
   };

   const clearSelection = () => {
      if (hasMintOccurred) {
         fetchClaimStatus(traitMetadata, selectedContract.address);
         setHasMintOccurred(false); // Reset the flag
      }
      selectedIdRef.current = null;
      setSelectedId(null);
      setRenderTrigger({});
      if (controls) {
         controls.start("visible");
      }
   };

   const imageVariants = {
      hidden: { scale: 0.5, opacity: 0, transition: { duration: 0.3 } },
      visible: { scale: 1, opacity: 1, transition: { duration: 0.3 } },
   };

   const backdropVariants = {
      visible: { opacity: 1, transition: { duration: 0.5 } },
      hidden: { opacity: 0, transition: { duration: 0.5 } },
   };

   const infoPanelVariants = {
      hidden: { x: "100%", opacity: 0, transition: { duration: 0.3 } },
      visible: { x: 0, opacity: 1, transition: { duration: 0.3 } },
   };

   const renderContent = () => {
      if (isLoading || isFetchingClaimStatus) {
         return <div>Loading...</div>;
      }
      if (mergedMetadata.length === 0) {
         return <div>No items in your inventory.</div>;
      }
      return (
         <AnimatePresence>
            {selectedIdRef.current && (
               <>
                  <motion.div
                     style={{
                        position: "fixed",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100vh",
                        background: "rgba(0, 0, 0, 0.9)",
                        zIndex: 2,
                        pointerEvents: selectedIdRef.current ? "auto" : "none",
                     }}
                     initial="hidden"
                     animate="visible"
                     exit="hidden"
                     variants={backdropVariants}
                     onClick={clearSelection}
                  />
                  <div
                     style={{
                        position: "fixed",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100vh",
                        zIndex: 3,
                        pointerEvents: "none",
                     }}
                  />
                  <motion.div
                     key="gridImageDisplay"
                     initial="hidden"
                     animate="visible"
                     exit="hidden"
                     variants={imageVariants}
                  >
                     <GridImageDisplay
                        selectedItem={selectedContract}
                        clearSelection={clearSelection}
                     />
                  </motion.div>
                  <motion.div
                     className="infoPanel"
                     initial="hidden"
                     animate="visible"
                     exit="hidden"
                     variants={infoPanelVariants}
                     style={{ zIndex: 11 }}
                     onClick={(e) => e.stopPropagation()}
                  >
                     <InventoryItemInfoAndClaim
                        selectedContract={selectedContract}
                        selectedItem={getSelectedItem()}
                        statusClaimProp={statusClaim}
                        pendingClaimsProp={pendingClaims}
                        onMintAction={handleMintAction}
                     />
                  </motion.div>
               </>
            )}
         </AnimatePresence>
      );
   };

   return (
      <LayoutGroup>
         <ItemsDisplay
            items={mergedMetadata}
            setSelectedId={setSelectedIdWithAnimation}
            heading="Inventory"
            isLoading={isLoading || isFetchingClaimStatus}
            cardSizes={selectedContract.cardSizes}
            selectedId={selectedId}
         >
            <InventoryTabs
               contracts={contracts}
               onContractSelect={handleFetchTokensByContract}
            />
         </ItemsDisplay>

         {renderContent()}
      </LayoutGroup>
   );
};

export default Inventory;
