import React, { useState, useContext, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./InventoryItemInfoAndClaim.scss";
import CardComponent from "./CardComponent";
import ListWithMotion from "./ListWithMotion";
import { TokenMetadataContext } from "./contexts/TokenMetadataContext";
import useClaim from "./hooks/useClaim";
import { contracts } from "./static/contractData.js";
import SpecificClaimNavigator from "./SpecificClaimNavigator.js";
import useApi from "./hooks/useApi";

const InventoryItemInfoAndClaim = ({
   selectedContract = null, // Default values if props are null
   statusClaimProp = null,
   selectedItem = null,
   pendingClaimsProp = null,
   onMintAction = () => {},
}) => {
   const { isWalletConnected } = useContext(TokenMetadataContext);
   const [selectedClaim, setSelectedClaim] = useState(null);
   const [tokenIds, setTokenIds] = useState(new Set());
   const [currentPage, setCurrentPage] = useState(1);
   const maxClaimAmount = 50;
   const tokensPerPage = 50;
   const { sendRequest } = useApi();

   const [statusClaim, setStatusClaim] = useState(statusClaimProp);
   const [pendingClaims, setPendingClaims] = useState(pendingClaimsProp);
   console.log("selected item", selectedItem);

   useEffect(() => {
      // Update local state when props change
      setStatusClaim(statusClaimProp);
      setPendingClaims(pendingClaimsProp);
   }, [statusClaimProp, pendingClaimsProp]);

   // Mapping from claim type to contract ID
   const claimTypeToContractId = {
      "Mutant Mage": "mutant_mage",
      $GM: "gm",
      "Genesis Mage": "genesis_mage",
   };
   const fetchUpdatedClaimStatus = async () => {
      // Similar to the fetchClaimStatus in the parent component
      const endpoint =
         "http://localhost:8080/api/public/genesis-passes/claim-status";
      try {
         const response = await sendRequest("GET", endpoint, {});

         if (response) {
            // Logic to process response and update local states
            const newStatusClaim = response.statusClaim; // Process response as needed
            const newPendingClaims = response.pendingClaims;
            setStatusClaim(newStatusClaim);
            setPendingClaims(newPendingClaims);
         }
      } catch (error) {
         console.error("Error fetching updated claim status:", error);
      }
   };

   // Derive contract ID from selected claim
   const contractId = claimTypeToContractId[selectedClaim];
   const { mint, loading } = useClaim(contractId, Array.from(tokenIds));

   const handleClaimButtonClick = (claimType) => {
      setSelectedClaim(claimType);
      setTokenIds(new Set(statusClaim[claimType].slice(0, maxClaimAmount)));
   };
   const handleMintSuccess = async () => {
      mint();
      onMintAction();
      await fetchUpdatedClaimStatus();
      const nextTokenIds = getNextSetOfTokenIds();
      setTokenIds(new Set(nextTokenIds));
   };

   const getNextSetOfTokenIds = () => {
      const updatedTokenIds = statusClaim[selectedClaim] || [];
      return updatedTokenIds.slice(0, maxClaimAmount);
   };

   useEffect(() => {
      // Whenever statusClaim updates, select the next set of token IDs
      try {
         const nextTokenIds = getNextSetOfTokenIds();

         // Check if nextTokenIds is not null or undefined
         if (nextTokenIds !== null && nextTokenIds !== undefined) {
            setTokenIds(new Set(nextTokenIds));
         } else {
            // Handle the case where nextTokenIds is null or undefined
            console.error("Error: nextTokenIds is null or undefined.");
            // Optionally, set tokenIds to an empty set or handle the error as needed
            setTokenIds(new Set());
         }
      } catch (error) {
         console.error("An error occurred: ", error);

         setTokenIds(new Set());
      }
   }, [statusClaim, selectedClaim]);

   const handleTokenIdToggle = (tokenId) => {
      setTokenIds((prevTokenIds) => {
         const newTokenIds = new Set(prevTokenIds);
         if (newTokenIds.has(tokenId)) {
            newTokenIds.delete(tokenId);
         } else {
            if (newTokenIds.size < maxClaimAmount) {
               newTokenIds.add(tokenId);
            } else {
               toast.error(
                  `Maximum claim amount of ${maxClaimAmount} reached.`,
                  {
                     position: "top-right",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     theme: "dark",
                  }
               );
            }
         }
         return newTokenIds;
      });
   };

   const handleBackButtonClick = () => {
      setSelectedClaim(null);
   };
   const [showingPendingClaims, setShowingPendingClaims] = useState(false);

   // Function to handle Pending Claims button click
   const handlePendingClaimsClick = () => {
      setShowingPendingClaims(!showingPendingClaims);
   };

   // Check if there are any pending claims
   const hasPendingClaims =
      pendingClaims && Object.keys(pendingClaims).length > 0;

   const renderClaimButtons = () => {
      // Assuming variables like selectedItem, statusClaim, showingPendingClaims, etc., are in scope

      if (!selectedItem || !statusClaim) {
         return <p>No claim status available.</p>;
      }

      // Render SpecificClaimNavigator for specific pending claims
      if (showingPendingClaims && hasPendingClaims) {
         // Prepare data for SpecificClaimNavigator
         const specificClaimsData = Object.entries(pendingClaims).flatMap(
            ([address, claimDataList]) => {
               return claimDataList
                  .map((claimData) => {
                     const contract = contracts.find(
                        (c) => c.address === address
                     );
                     // Assuming claimType is a property of contract
                     return contract
                        ? {
                             ...claimData, // original data
                             claimType: contract.claimType,
                             contractId: contract.id,
                          }
                        : null;
                  })
                  .filter((contract) => contract !== null);
            }
         );

         return (
            <SpecificClaimNavigator
               specificClaims={specificClaimsData}
               handleBackButtonClick={handlePendingClaimsClick}
               selectedClaim={selectedClaim}
               handleClaimSuccess={fetchUpdatedClaimStatus}
               onClaim={onMintAction}
            />
         );
      }

      // Render buttons for existing claims
      const existingClaimButtons = Object.entries(statusClaim).map(
         ([claimType, tokenIds], index) => (
            <motion.div key={index} className="Inv-Claim-Cell">
               {tokenIds.length > 0 ? (
                  <button
                     className="Inv-Claim-Button"
                     onClick={() => handleClaimButtonClick(claimType)}
                  >
                     {claimType}
                  </button>
               ) : (
                  <span className="Inv-Claim-Text">
                     {claimType}: None available
                  </span>
               )}
            </motion.div>
         )
      );

      // Render a general "Pending Claims" button
      const pendingClaimsButton = hasPendingClaims && !showingPendingClaims && (
         <motion.div className="Inv-Claim-Cell">
            <button
               className="Inv-Claim-Button"
               onClick={handlePendingClaimsClick}
            >
               Pending Claims
            </button>
         </motion.div>
      );

      return (
         <>
            <div className="Inventory-Item-Subheading">
               <h2>
                  <span>Claims</span>
               </h2>
            </div>
            <div className="Inv-Claim-Grid">
               {existingClaimButtons}
               {pendingClaimsButton}
            </div>
         </>
      );
   };

   const renderClaimHeader = () => {
      return (
         selectedClaim && (
            <div className="Inventory-Item-Subheading">
               <h2>
                  <span>{selectedClaim} Claim</span>
                  <button
                     className="Exit-Button"
                     onClick={handleBackButtonClick}
                  >
                     &#x2715; {/* Unicode X character */}
                  </button>
               </h2>
            </div>
         )
      );
   };

   const renderTokenIdSelection = () => {
      const startIndex = (currentPage - 1) * tokensPerPage;
      const selectedTokenIds = statusClaim[selectedClaim] || [];
      const totalPages = Math.ceil(selectedTokenIds.length / tokensPerPage);
      const tokenIdsToShow = selectedTokenIds.slice(
         startIndex,
         startIndex + tokensPerPage
      );

      return (
         <>
            {renderClaimHeader()}
            <div className="Token-Selection-Container">
               {totalPages > 1 && (
                  <button
                     className="Page-Navigation-Button"
                     onClick={() => setCurrentPage(currentPage - 1)}
                     disabled={currentPage === 1}
                  >
                     {"<"}
                  </button>
               )}
               <div className="Inventory-Item-Token-Id-Selection-Grid">
                  {tokenIdsToShow.map((tokenId) => (
                     <button
                        key={tokenId}
                        className={`Inventory-Item-Pushable ${
                           tokenIds.has(tokenId) ? "selected" : ""
                        }`}
                        onClick={() => handleTokenIdToggle(tokenId)}
                     >
                        <span className="Inventory-Item-Shadow"></span>
                        <span className="Inventory-Item-Edge"></span>
                        <span
                           className={`Inventory-Item-Front ${
                              tokenIds.has(tokenId) ? "selected" : ""
                           }`}
                        >
                           {tokenId}
                        </span>
                     </button>
                  ))}
               </div>
               {totalPages > 1 && (
                  <button
                     className="Page-Navigation-Button"
                     onClick={() => setCurrentPage(currentPage + 1)}
                     disabled={currentPage === totalPages}
                  >
                     {">"}
                  </button>
               )}
            </div>
            {selectedClaim && (
               <div className="Claim-Button-Container">
                  <button
                     className="Claim-Button"
                     onClick={() => handleMintSuccess()}
                     disabled={loading}
                  >
                     Claim x {tokenIds.size}
                  </button>
               </div>
            )}
         </>
      );
   };

   return (
      <>
         <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
         />
         <div className="Inv-Item-Info-Container">
            <div className="Inv-Item-Info-Heading">
               <h1>
                  <span>{selectedItem.name}</span>
               </h1>
            </div>
            <div className="Inv-Item-Info-Card">
               <CardComponent
                  imageSrc={selectedItem.image}
                  scaleOnMouseEnter={1.1}
               />
            </div>
            <div className="Inv-Item-Info-Mint">
               {isWalletConnected ? (
                  !selectedClaim ? (
                     renderClaimButtons()
                  ) : (
                     renderTokenIdSelection()
                  )
               ) : (
                  <div>Wallet not connected</div>
               )}
            </div>
            <div className="Inv-Item-Info-Footer"></div>
         </div>
      </>
   );
};

export default InventoryItemInfoAndClaim;
