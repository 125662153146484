import pfp from "./pfp.png";
import genesisMage from "./9982.png";
import closedChest from "./assets/icons/closedChest.png";
import lore from "./assets/icons/lore.png";
import genesisMageHat from "./assets/icons/genesisMageHat.png";
import mutantMageHat from "./assets/icons/mutantMageHat.png";
import trophy from "./assets/icons/trophy.png";
import mutantMage from "./mutantMage.png";
import sword from "./sword.png";
import coin from "./coin.png";
import genesisPass from "./mage-game.svg";
import MGTextLogo from "./assets/svgs/MGTextLogo.svg";
const contractAddress = "0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0";
const contractABI = [
   // The 'mint' function you provided
   {
      constant: false,
      inputs: [
         {
            name: "quantity",
            type: "uint256",
         },
      ],
      name: "mint",
      outputs: [],
      payable: true,
      stateMutability: "payable",
      type: "function",
   },
   // The 'totalSupply' function to read the total number of tokens minted
   {
      constant: true,
      inputs: [],
      name: "totalSupply",
      outputs: [
         {
            name: "",
            type: "uint256",
         },
      ],
      payable: false,
      stateMutability: "view",
      type: "function",
   },
   {
      constant: true,
      inputs: [],
      name: "getMaxSupply",
      outputs: [
         {
            name: "",
            type: "uint256",
         },
      ],
      payable: false,
      stateMutability: "pure",
      type: "function",
   },
   {
      constant: true,
      inputs: [],
      name: "getMaxMintQuantity",
      outputs: [
         {
            name: "",
            type: "uint256",
         },
      ],
      payable: false,
      stateMutability: "pure",
      type: "function",
   },
];

function generateShopItems(numberOfItems) {
   const items = [];
   for (let i = 1; i <= numberOfItems; i++) {
      items.push({
         id: i,
         imageUrl: MGTextLogo,
         title: "Genesis Pass",
         description: "An enigmatic painting of a forest shrouded in mystery.",
         price: 0.03,
         maxMintQuantity: 50,
         chainId: 31337,
         duration: "3 days left",
         creatorName: "ArtsyCanvas",
         creatorImageUrl: pfp,
         image: MGTextLogo,
         contractAddress: contractAddress,
         contractABI: contractABI,
         totalAvailable: 11011,
         bulletPoints: [
            { icon: genesisMageHat, text: "Genesis Mage Mint", quantity: true },
            { icon: mutantMageHat, text: "Mutant Mage Mint", quantity: true },
            { icon: closedChest, text: "1M $GM Airdrop", quantity: true },
            { icon: lore, text: "2x Points Multipler", quantity: false },
            
         ],
         quadrants: [
            { image: genesisMage, heading: "Genesis Mage" },
            { image: mutantMage, heading: "Mutant Mage" },
            { image: coin, heading: "$GM" },
            { image: sword, heading: "PvP" },
         ],
         attributes: [{ trait_type: "Genesis Pass", value: "True" }],
         videoSrc: "./MGCompressed.mp4",
         textBandTop: "Genesis Pass",
         textBandBottom: "0.03 Ξ",
      });
   }

   return items;
}

const shopItemsData = generateShopItems(1); // Generate 10 items

export default shopItemsData;
