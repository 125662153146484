// static/contractData.js
import pfp from "../pfp.png";
import genesisMage from "../9982.png";
import closedChest from "../assets/icons/closedChest.png";
import lore from "../assets/icons/lore.png";
import genesisMageHat from "../assets/icons/genesisMageHat.png";
import mutantMageHat from "../assets/icons/mutantMageHat.png";
import trophy from "../assets/icons/trophy.png";
import mutantMage from "../mutantMage.png";
import sword from "../sword.png";
import coin from "../coin.png";
import genesisPass from "../mage-game.svg";

const sharedAbi = [
   {
      inputs: [],
      stateMutability: "nonpayable",
      type: "constructor",
   },
   {
      inputs: [],
      name: "ApprovalCallerNotOwnerNorApproved",
      type: "error",
   },
   {
      inputs: [],
      name: "ApprovalQueryForNonexistentToken",
      type: "error",
   },
   {
      inputs: [],
      name: "BalanceQueryForZeroAddress",
      type: "error",
   },
   {
      inputs: [],
      name: "InvalidQueryRange",
      type: "error",
   },
   {
      inputs: [],
      name: "MageGameGenesisCharacters__MaxMintQuantityExceeded",
      type: "error",
   },
   {
      inputs: [],
      name: "MageGameGenesisCharacters__MaxSupplyExceeded",
      type: "error",
   },
   {
      inputs: [],
      name: "MageGameGenesisCharacters__NotEnoughEtherSent",
      type: "error",
   },
   {
      inputs: [],
      name: "MintERC2309QuantityExceedsLimit",
      type: "error",
   },
   {
      inputs: [],
      name: "MintToZeroAddress",
      type: "error",
   },
   {
      inputs: [],
      name: "MintZeroQuantity",
      type: "error",
   },
   {
      inputs: [],
      name: "OwnerQueryForNonexistentToken",
      type: "error",
   },
   {
      inputs: [],
      name: "OwnershipNotInitializedForExtraData",
      type: "error",
   },
   {
      inputs: [],
      name: "TransferCallerNotOwnerNorApproved",
      type: "error",
   },
   {
      inputs: [],
      name: "TransferFromIncorrectOwner",
      type: "error",
   },
   {
      inputs: [],
      name: "TransferToNonERC721ReceiverImplementer",
      type: "error",
   },
   {
      inputs: [],
      name: "TransferToZeroAddress",
      type: "error",
   },
   {
      inputs: [],
      name: "URIQueryForNonexistentToken",
      type: "error",
   },
   {
      anonymous: false,
      inputs: [
         {
            indexed: true,
            internalType: "address",
            name: "owner",
            type: "address",
         },
         {
            indexed: true,
            internalType: "address",
            name: "approved",
            type: "address",
         },
         {
            indexed: true,
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
         },
      ],
      name: "Approval",
      type: "event",
   },
   {
      anonymous: false,
      inputs: [
         {
            indexed: true,
            internalType: "address",
            name: "owner",
            type: "address",
         },
         {
            indexed: true,
            internalType: "address",
            name: "operator",
            type: "address",
         },
         {
            indexed: false,
            internalType: "bool",
            name: "approved",
            type: "bool",
         },
      ],
      name: "ApprovalForAll",
      type: "event",
   },
   {
      anonymous: false,
      inputs: [
         {
            indexed: true,
            internalType: "uint256",
            name: "fromTokenId",
            type: "uint256",
         },
         {
            indexed: false,
            internalType: "uint256",
            name: "toTokenId",
            type: "uint256",
         },
         {
            indexed: true,
            internalType: "address",
            name: "from",
            type: "address",
         },
         {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
         },
      ],
      name: "ConsecutiveTransfer",
      type: "event",
   },
   {
      anonymous: false,
      inputs: [
         {
            indexed: true,
            internalType: "address",
            name: "previousOwner",
            type: "address",
         },
         {
            indexed: true,
            internalType: "address",
            name: "newOwner",
            type: "address",
         },
      ],
      name: "OwnershipTransferred",
      type: "event",
   },
   {
      anonymous: false,
      inputs: [
         {
            indexed: true,
            internalType: "address",
            name: "from",
            type: "address",
         },
         {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
         },
         {
            indexed: true,
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
         },
      ],
      name: "Transfer",
      type: "event",
   },
   {
      inputs: [],
      name: "MAX_MINT_QUANTITY",
      outputs: [
         {
            internalType: "uint256",
            name: "",
            type: "uint256",
         },
      ],
      stateMutability: "view",
      type: "function",
   },
   {
      inputs: [],
      name: "MAX_SUPPLY",
      outputs: [
         {
            internalType: "uint256",
            name: "",
            type: "uint256",
         },
      ],
      stateMutability: "view",
      type: "function",
   },
   {
      inputs: [],
      name: "PACK_PRICE",
      outputs: [
         {
            internalType: "uint256",
            name: "",
            type: "uint256",
         },
      ],
      stateMutability: "view",
      type: "function",
   },
   {
      inputs: [
         {
            internalType: "address",
            name: "to",
            type: "address",
         },
         {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
         },
      ],
      name: "approve",
      outputs: [],
      stateMutability: "payable",
      type: "function",
   },
   {
      inputs: [
         {
            internalType: "address",
            name: "owner",
            type: "address",
         },
      ],
      name: "balanceOf",
      outputs: [
         {
            internalType: "uint256",
            name: "",
            type: "uint256",
         },
      ],
      stateMutability: "view",
      type: "function",
   },
   {
      inputs: [
         {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
         },
      ],
      name: "explicitOwnershipOf",
      outputs: [
         {
            components: [
               {
                  internalType: "address",
                  name: "addr",
                  type: "address",
               },
               {
                  internalType: "uint64",
                  name: "startTimestamp",
                  type: "uint64",
               },
               {
                  internalType: "bool",
                  name: "burned",
                  type: "bool",
               },
               {
                  internalType: "uint24",
                  name: "extraData",
                  type: "uint24",
               },
            ],
            internalType: "struct IERC721A.TokenOwnership",
            name: "",
            type: "tuple",
         },
      ],
      stateMutability: "view",
      type: "function",
   },
   {
      inputs: [
         {
            internalType: "uint256[]",
            name: "tokenIds",
            type: "uint256[]",
         },
      ],
      name: "explicitOwnershipsOf",
      outputs: [
         {
            components: [
               {
                  internalType: "address",
                  name: "addr",
                  type: "address",
               },
               {
                  internalType: "uint64",
                  name: "startTimestamp",
                  type: "uint64",
               },
               {
                  internalType: "bool",
                  name: "burned",
                  type: "bool",
               },
               {
                  internalType: "uint24",
                  name: "extraData",
                  type: "uint24",
               },
            ],
            internalType: "struct IERC721A.TokenOwnership[]",
            name: "",
            type: "tuple[]",
         },
      ],
      stateMutability: "view",
      type: "function",
   },
   {
      inputs: [
         {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
         },
      ],
      name: "getApproved",
      outputs: [
         {
            internalType: "address",
            name: "",
            type: "address",
         },
      ],
      stateMutability: "view",
      type: "function",
   },
   {
      inputs: [],
      name: "getBaseURI",
      outputs: [
         {
            internalType: "string",
            name: "",
            type: "string",
         },
      ],
      stateMutability: "view",
      type: "function",
   },
   {
      inputs: [],
      name: "getMaxMintQuantity",
      outputs: [
         {
            internalType: "uint256",
            name: "",
            type: "uint256",
         },
      ],
      stateMutability: "pure",
      type: "function",
   },
   {
      inputs: [],
      name: "getMaxSupply",
      outputs: [
         {
            internalType: "uint256",
            name: "",
            type: "uint256",
         },
      ],
      stateMutability: "pure",
      type: "function",
   },
   {
      inputs: [
         {
            internalType: "address",
            name: "owner",
            type: "address",
         },
         {
            internalType: "address",
            name: "operator",
            type: "address",
         },
      ],
      name: "isApprovedForAll",
      outputs: [
         {
            internalType: "bool",
            name: "",
            type: "bool",
         },
      ],
      stateMutability: "view",
      type: "function",
   },
   {
      inputs: [
         {
            internalType: "uint256",
            name: "quantity",
            type: "uint256",
         },
      ],
      name: "mint",
      outputs: [],
      stateMutability: "payable",
      type: "function",
   },
   {
      inputs: [],
      name: "name",
      outputs: [
         {
            internalType: "string",
            name: "",
            type: "string",
         },
      ],
      stateMutability: "view",
      type: "function",
   },
   {
      inputs: [],
      name: "owner",
      outputs: [
         {
            internalType: "address",
            name: "",
            type: "address",
         },
      ],
      stateMutability: "view",
      type: "function",
   },
   {
      inputs: [
         {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
         },
      ],
      name: "ownerOf",
      outputs: [
         {
            internalType: "address",
            name: "",
            type: "address",
         },
      ],
      stateMutability: "view",
      type: "function",
   },
   {
      inputs: [],
      name: "renounceOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
   },
   {
      inputs: [
         {
            internalType: "address",
            name: "from",
            type: "address",
         },
         {
            internalType: "address",
            name: "to",
            type: "address",
         },
         {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
         },
      ],
      name: "safeTransferFrom",
      outputs: [],
      stateMutability: "payable",
      type: "function",
   },
   {
      inputs: [
         {
            internalType: "address",
            name: "from",
            type: "address",
         },
         {
            internalType: "address",
            name: "to",
            type: "address",
         },
         {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
         },
         {
            internalType: "bytes",
            name: "_data",
            type: "bytes",
         },
      ],
      name: "safeTransferFrom",
      outputs: [],
      stateMutability: "payable",
      type: "function",
   },
   {
      inputs: [
         {
            internalType: "address",
            name: "operator",
            type: "address",
         },
         {
            internalType: "bool",
            name: "approved",
            type: "bool",
         },
      ],
      name: "setApprovalForAll",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
   },
   {
      inputs: [
         {
            internalType: "string",
            name: "baseURI",
            type: "string",
         },
      ],
      name: "setBaseURI",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
   },
   {
      inputs: [
         {
            internalType: "bytes4",
            name: "interfaceId",
            type: "bytes4",
         },
      ],
      name: "supportsInterface",
      outputs: [
         {
            internalType: "bool",
            name: "",
            type: "bool",
         },
      ],
      stateMutability: "view",
      type: "function",
   },
   {
      inputs: [],
      name: "symbol",
      outputs: [
         {
            internalType: "string",
            name: "",
            type: "string",
         },
      ],
      stateMutability: "view",
      type: "function",
   },
   {
      inputs: [
         {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
         },
      ],
      name: "tokenURI",
      outputs: [
         {
            internalType: "string",
            name: "",
            type: "string",
         },
      ],
      stateMutability: "view",
      type: "function",
   },
   {
      inputs: [
         {
            internalType: "address",
            name: "owner",
            type: "address",
         },
      ],
      name: "tokensOfOwner",
      outputs: [
         {
            internalType: "uint256[]",
            name: "",
            type: "uint256[]",
         },
      ],
      stateMutability: "view",
      type: "function",
   },
   {
      inputs: [
         {
            internalType: "address",
            name: "owner",
            type: "address",
         },
         {
            internalType: "uint256",
            name: "start",
            type: "uint256",
         },
         {
            internalType: "uint256",
            name: "stop",
            type: "uint256",
         },
      ],
      name: "tokensOfOwnerIn",
      outputs: [
         {
            internalType: "uint256[]",
            name: "",
            type: "uint256[]",
         },
      ],
      stateMutability: "view",
      type: "function",
   },
   {
      inputs: [],
      name: "totalSupply",
      outputs: [
         {
            internalType: "uint256",
            name: "",
            type: "uint256",
         },
      ],
      stateMutability: "view",
      type: "function",
   },
   {
      inputs: [
         {
            internalType: "address",
            name: "from",
            type: "address",
         },
         {
            internalType: "address",
            name: "to",
            type: "address",
         },
         {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
         },
      ],
      name: "transferFrom",
      outputs: [],
      stateMutability: "payable",
      type: "function",
   },
   {
      inputs: [
         {
            internalType: "address",
            name: "newOwner",
            type: "address",
         },
      ],
      name: "transferOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
   },
];

const magesAbi = [
   { type: "constructor", inputs: [], stateMutability: "nonpayable" },
   {
      type: "function",
      name: "MAX_MINT_QUANTITY",
      inputs: [],
      outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
      stateMutability: "view",
   },
   {
      type: "function",
      name: "MAX_SUPPLY",
      inputs: [],
      outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
      stateMutability: "view",
   },
   {
      type: "function",
      name: "approve",
      inputs: [
         { name: "to", type: "address", internalType: "address" },
         { name: "tokenId", type: "uint256", internalType: "uint256" },
      ],
      outputs: [],
      stateMutability: "payable",
   },
   {
      type: "function",
      name: "balanceOf",
      inputs: [{ name: "owner", type: "address", internalType: "address" }],
      outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
      stateMutability: "view",
   },
   {
      type: "function",
      name: "explicitOwnershipOf",
      inputs: [{ name: "tokenId", type: "uint256", internalType: "uint256" }],
      outputs: [
         {
            name: "",
            type: "tuple",
            internalType: "struct IERC721A.TokenOwnership",
            components: [
               { name: "addr", type: "address", internalType: "address" },
               {
                  name: "startTimestamp",
                  type: "uint64",
                  internalType: "uint64",
               },
               { name: "burned", type: "bool", internalType: "bool" },
               { name: "extraData", type: "uint24", internalType: "uint24" },
            ],
         },
      ],
      stateMutability: "view",
   },
   {
      type: "function",
      name: "explicitOwnershipsOf",
      inputs: [
         { name: "tokenIds", type: "uint256[]", internalType: "uint256[]" },
      ],
      outputs: [
         {
            name: "",
            type: "tuple[]",
            internalType: "struct IERC721A.TokenOwnership[]",
            components: [
               { name: "addr", type: "address", internalType: "address" },
               {
                  name: "startTimestamp",
                  type: "uint64",
                  internalType: "uint64",
               },
               { name: "burned", type: "bool", internalType: "bool" },
               { name: "extraData", type: "uint24", internalType: "uint24" },
            ],
         },
      ],
      stateMutability: "view",
   },
   {
      type: "function",
      name: "getApproved",
      inputs: [{ name: "tokenId", type: "uint256", internalType: "uint256" }],
      outputs: [{ name: "", type: "address", internalType: "address" }],
      stateMutability: "view",
   },
   {
      type: "function",
      name: "getBaseURI",
      inputs: [],
      outputs: [{ name: "", type: "string", internalType: "string" }],
      stateMutability: "view",
   },
   {
      type: "function",
      name: "getMaxMintQuantity",
      inputs: [],
      outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
      stateMutability: "pure",
   },
   {
      type: "function",
      name: "getMaxSupply",
      inputs: [],
      outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
      stateMutability: "pure",
   },
   {
      type: "function",
      name: "isApprovedForAll",
      inputs: [
         { name: "owner", type: "address", internalType: "address" },
         { name: "operator", type: "address", internalType: "address" },
      ],
      outputs: [{ name: "", type: "bool", internalType: "bool" }],
      stateMutability: "view",
   },
   {
      type: "function",
      name: "name",
      inputs: [],
      outputs: [{ name: "", type: "string", internalType: "string" }],
      stateMutability: "view",
   },
   {
      type: "function",
      name: "owner",
      inputs: [],
      outputs: [{ name: "", type: "address", internalType: "address" }],
      stateMutability: "view",
   },
   {
      type: "function",
      name: "ownerOf",
      inputs: [{ name: "tokenId", type: "uint256", internalType: "uint256" }],
      outputs: [{ name: "", type: "address", internalType: "address" }],
      stateMutability: "view",
   },
   {
      type: "function",
      name: "renounceOwnership",
      inputs: [],
      outputs: [],
      stateMutability: "nonpayable",
   },
   {
      type: "function",
      name: "safeTransferFrom",
      inputs: [
         { name: "from", type: "address", internalType: "address" },
         { name: "to", type: "address", internalType: "address" },
         { name: "tokenId", type: "uint256", internalType: "uint256" },
      ],
      outputs: [],
      stateMutability: "payable",
   },
   {
      type: "function",
      name: "safeTransferFrom",
      inputs: [
         { name: "from", type: "address", internalType: "address" },
         { name: "to", type: "address", internalType: "address" },
         { name: "tokenId", type: "uint256", internalType: "uint256" },
         { name: "_data", type: "bytes", internalType: "bytes" },
      ],
      outputs: [],
      stateMutability: "payable",
   },
   {
      type: "function",
      name: "setApprovalForAll",
      inputs: [
         { name: "operator", type: "address", internalType: "address" },
         { name: "approved", type: "bool", internalType: "bool" },
      ],
      outputs: [],
      stateMutability: "nonpayable",
   },
   {
      type: "function",
      name: "setBaseURI",
      inputs: [{ name: "baseURI", type: "string", internalType: "string" }],
      outputs: [],
      stateMutability: "nonpayable",
   },
   {
      type: "function",
      name: "setSigner",
      inputs: [{ name: "newSigner", type: "address", internalType: "address" }],
      outputs: [],
      stateMutability: "nonpayable",
   },
   {
      type: "function",
      name: "signatureMint",
      inputs: [
         { name: "tokenIds", type: "uint256[]", internalType: "uint256[]" },
         { name: "signature", type: "bytes", internalType: "bytes" },
      ],
      outputs: [],
      stateMutability: "nonpayable",
   },
   {
      type: "function",
      name: "supportsInterface",
      inputs: [{ name: "interfaceId", type: "bytes4", internalType: "bytes4" }],
      outputs: [{ name: "", type: "bool", internalType: "bool" }],
      stateMutability: "view",
   },
   {
      type: "function",
      name: "symbol",
      inputs: [],
      outputs: [{ name: "", type: "string", internalType: "string" }],
      stateMutability: "view",
   },
   {
      type: "function",
      name: "tokenURI",
      inputs: [{ name: "tokenId", type: "uint256", internalType: "uint256" }],
      outputs: [{ name: "", type: "string", internalType: "string" }],
      stateMutability: "view",
   },
   {
      type: "function",
      name: "tokensOfOwner",
      inputs: [{ name: "owner", type: "address", internalType: "address" }],
      outputs: [{ name: "", type: "uint256[]", internalType: "uint256[]" }],
      stateMutability: "view",
   },
   {
      type: "function",
      name: "tokensOfOwnerIn",
      inputs: [
         { name: "owner", type: "address", internalType: "address" },
         { name: "start", type: "uint256", internalType: "uint256" },
         { name: "stop", type: "uint256", internalType: "uint256" },
      ],
      outputs: [{ name: "", type: "uint256[]", internalType: "uint256[]" }],
      stateMutability: "view",
   },
   {
      type: "function",
      name: "totalSupply",
      inputs: [],
      outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
      stateMutability: "view",
   },
   {
      type: "function",
      name: "transferFrom",
      inputs: [
         { name: "from", type: "address", internalType: "address" },
         { name: "to", type: "address", internalType: "address" },
         { name: "tokenId", type: "uint256", internalType: "uint256" },
      ],
      outputs: [],
      stateMutability: "payable",
   },
   {
      type: "function",
      name: "transferOwnership",
      inputs: [{ name: "newOwner", type: "address", internalType: "address" }],
      outputs: [],
      stateMutability: "nonpayable",
   },
   {
      type: "event",
      name: "Approval",
      inputs: [
         {
            name: "owner",
            type: "address",
            indexed: true,
            internalType: "address",
         },
         {
            name: "approved",
            type: "address",
            indexed: true,
            internalType: "address",
         },
         {
            name: "tokenId",
            type: "uint256",
            indexed: true,
            internalType: "uint256",
         },
      ],
      anonymous: false,
   },
   {
      type: "event",
      name: "ApprovalForAll",
      inputs: [
         {
            name: "owner",
            type: "address",
            indexed: true,
            internalType: "address",
         },
         {
            name: "operator",
            type: "address",
            indexed: true,
            internalType: "address",
         },
         {
            name: "approved",
            type: "bool",
            indexed: false,
            internalType: "bool",
         },
      ],
      anonymous: false,
   },
   {
      type: "event",
      name: "ConsecutiveTransfer",
      inputs: [
         {
            name: "fromTokenId",
            type: "uint256",
            indexed: true,
            internalType: "uint256",
         },
         {
            name: "toTokenId",
            type: "uint256",
            indexed: false,
            internalType: "uint256",
         },
         {
            name: "from",
            type: "address",
            indexed: true,
            internalType: "address",
         },
         {
            name: "to",
            type: "address",
            indexed: true,
            internalType: "address",
         },
      ],
      anonymous: false,
   },
   {
      type: "event",
      name: "OwnershipTransferred",
      inputs: [
         {
            name: "previousOwner",
            type: "address",
            indexed: true,
            internalType: "address",
         },
         {
            name: "newOwner",
            type: "address",
            indexed: true,
            internalType: "address",
         },
      ],
      anonymous: false,
   },
   {
      type: "event",
      name: "Transfer",
      inputs: [
         {
            name: "from",
            type: "address",
            indexed: true,
            internalType: "address",
         },
         {
            name: "to",
            type: "address",
            indexed: true,
            internalType: "address",
         },
         {
            name: "tokenId",
            type: "uint256",
            indexed: true,
            internalType: "uint256",
         },
      ],
      anonymous: false,
   },
   { type: "error", name: "ApprovalCallerNotOwnerNorApproved", inputs: [] },
   { type: "error", name: "ApprovalQueryForNonexistentToken", inputs: [] },
   { type: "error", name: "BalanceQueryForZeroAddress", inputs: [] },
   { type: "error", name: "InvalidQueryRange", inputs: [] },
   {
      type: "error",
      name: "MageGameGenesisCharacters__InvalidSignature",
      inputs: [],
   },
   {
      type: "error",
      name: "MageGameGenesisCharacters__MaxSupplyExceeded",
      inputs: [],
   },
   {
      type: "error",
      name: "MageGameGenesisCharacters__NonSequentialTokenId",
      inputs: [],
   },
   {
      type: "error",
      name: "MageGameGenesisCharacters__UsedSignature",
      inputs: [],
   },
   { type: "error", name: "MintERC2309QuantityExceedsLimit", inputs: [] },
   { type: "error", name: "MintToZeroAddress", inputs: [] },
   { type: "error", name: "MintZeroQuantity", inputs: [] },
   { type: "error", name: "OwnerQueryForNonexistentToken", inputs: [] },
   { type: "error", name: "OwnershipNotInitializedForExtraData", inputs: [] },
   { type: "error", name: "TransferCallerNotOwnerNorApproved", inputs: [] },
   { type: "error", name: "TransferFromIncorrectOwner", inputs: [] },
   {
      type: "error",
      name: "TransferToNonERC721ReceiverImplementer",
      inputs: [],
   },
   { type: "error", name: "TransferToZeroAddress", inputs: [] },
   { type: "error", name: "URIQueryForNonexistentToken", inputs: [] },
];
export const contracts = [
   {
      id: "genesis_pass",
      name: "Genesis Pass",
      imageUrl: "",
      address: "0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0",
      abi: sharedAbi,
      chainId: 31337,
      bulletPoints: [
         { icon: genesisMageHat, text: "Genesis Mage Mint", quantity: true },
         { icon: mutantMageHat, text: "Mutant Mage Mint", quantity: true },
         { icon: closedChest, text: "2.147B $GM Airdrop", quantity: true },
         { icon: trophy, text: "PVP Tournaments", quantity: false },
         { icon: lore, text: "Game Update Polls", quantity: false },
      ],
      quadrants: [
         { image: genesisMage, heading: "Genesis Mage" },
         { image: mutantMage, heading: "Mutant Mage" },
         { image: coin, heading: "$GM" },
         { image: sword, heading: "Loot" },
      ],
      buyable: true,
      shopItems: [
         {
            // Example item data
            id: 1,
            title: "Special Genesis Pass",
            description: "Exclusive access to Genesis Mage features.",
            price: 0.05,
            // Add other necessary item properties here
         },
         // Add more items as needed
      ],
   },
   {
      id: "genesis_mage",
      name: "Genesis Mage",
      imageUrl: "",
      claimType: "genesis_mage",
      address: "0x5FbDB2315678afecb367f032d93F642f64180aa3",
      abi: magesAbi,
      chainId: 31337,
      cardSizes: {
         small: { width: "256px", height: "256px" },
         medium: { width: "256px", height: "256px" },
         large: { width: "256px", height: "256px" },
         xLarge: { width: "256px", height: "256px" },
         xxLarge: { width: "256px", height: "256px" },
      },
      buyable: false,
      shopItems: [], // No items since this contract is not buyable
   },
   // Add more contracts as needed
];
