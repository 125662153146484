import React, { useRef, useState, useContext, useEffect } from "react";
import { motion, AnimatePresence, LayoutGroup } from "framer-motion";
import { AnimationContext } from "./AnimationContext"; // Import the AnimationContext
import items from "./shopItemsData";
import ShopItemInfoAndPurchase from "./ShopItemInfoAndPurchase";
import "./Shop.scss";
import GridImageDisplay from "./GridImageDisplay";
import ItemsDisplay from "./ItemsDisplay";

const Shop = () => {
   const selectedIdRef = useRef(null);
   const [_, setRenderTrigger] = useState({});
   const { controls } = useContext(AnimationContext);

   const getSelectedItem = () => {
      return items.find((item) => item.id === selectedIdRef.current);
   };

   const setSelectedId = (id) => {
      selectedIdRef.current = id;
      setRenderTrigger({});
      if (id && controls) {
         controls.start("hidden");
      }
   };

   const clearSelection = () => {
      selectedIdRef.current = null;
      setRenderTrigger({});
      if (controls) {
         controls.start("visible");
      }
   };

   // Variants for animations
   const imageVariants = {
      hidden: { scale: 0.5, opacity: 0, transition: { duration: 0.3 } },
      visible: { scale: 1, opacity: 1, transition: { duration: 0.3 } },
   };

   const backdropVariants = {
      visible: { opacity: 1, transition: { duration: 0.5 } },
      hidden: { opacity: 0, transition: { duration: 0.5 } },
   };

   const infoPanelVariants = {
      hidden: { x: "100%", opacity: 0, transition: { duration: 0.3 } },
      visible: { x: 0, opacity: 1, transition: { duration: 0.3 } },
   };

   // Transition settings
   const transition = {
      type: "spring",
      stiffness: 100,
      damping: 20,
      duration: 0.3,
   };

   return (
      <LayoutGroup>
         <ItemsDisplay
            items={items}
            setSelectedId={setSelectedId}
            heading={"Shop"}
            isSidebarEnabled={false}
            showWithoutWallet={true}
         />
         <AnimatePresence>
            {selectedIdRef.current && (
               <>
                  <motion.div
                     style={{
                        position: "fixed",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100vh",
                        background: "rgba(0, 0, 0, 0.9)",
                        zIndex: 3,
                        pointerEvents: selectedIdRef.current ? "auto" : "none",
                     }}
                     initial="hidden"
                     animate="visible"
                     exit="hidden"
                     variants={backdropVariants}
                     transition={{ ...transition }}
                  />
                  <div
                     style={{
                        position: "fixed",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100vh",
                        zIndex: 4,
                        pointerEvents: "none", // Non-interactive layer
                     }}
                  />
                  <motion.div
                     key="gridImageDisplay"
                     initial="hidden"
                     animate="visible"
                     exit="hidden"
                     variants={imageVariants}
                  >
                     <GridImageDisplay
                        selectedItem={getSelectedItem()}
                        clearSelection={clearSelection}
                     />
                  </motion.div>
                  <motion.div
                     className="infoPanel"
                     initial="hidden"
                     animate="visible"
                     exit="hidden"
                     variants={infoPanelVariants}
                     transition={transition}
                     onClick={(e) => e.stopPropagation()}
                  >
                     <ShopItemInfoAndPurchase
                        selectedItem={getSelectedItem()}
                     />
                  </motion.div>
               </>
            )}
         </AnimatePresence>
      </LayoutGroup>
   );
};
export default Shop;
