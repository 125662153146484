import React, { useRef, useEffect, useState, useCallback } from "react";
import styled, { css, keyframes } from "styled-components";
import throttle from "lodash/throttle"; // Ensure lodash or lodash.throttle is installed

// Define breakpoints for media queries
const defaultBreakpoints = {
   small: { width: "950px", height: "750px" },
   medium: { width: "1150px", height: "900px" },
   large: { width: "1250px", height: "1040px" },
   xLarge: { width: "1950px", height: "1050px" },
   xxLarge: { width: "2100px", height: "1200px" },
};

// Default sizes if none are passed
const defaultSizes = {
   small: { width: "200px", height: "300px" },
   medium: { width: "240px", height: "360px" },
   large: { width: "280px", height: "420px" },
   xLarge: { width: "320px", height: "480px" },
   xxLarge: { width: "320px", height: "480px" },
};

const CardComponent = ({
   children,
   innerRefCallback,
   textBandTop,
   textBandBottom,
   imageSrc,
   videoSrc,
   scaleOnMouseEnter = 1.05,
   sizes = defaultSizes,
   breakpoints = defaultBreakpoints,
   onClick,
   onHover,
   onMouseLeave,
   enableRotation = true,
   enableScale = true,
   margin = "8px",
}) => {
   const cardRef = useRef(null);
   const rotateRef = useRef({ x: 0, y: 0 });
   const scaleRef = useRef(1);
   const videoRef = useRef(null);
   const [videoLoaded, setVideoLoaded] = useState(false);
   const [isMouseOver, setIsMouseOver] = useState(false);
   const [isImageLoaded, setIsImageLoaded] = useState(false);

   const updateTransform = useCallback(() => {
      if (cardRef.current) {
         const rotationTransform = enableRotation
            ? `rotateX(${rotateRef.current.x}deg) rotateY(${rotateRef.current.y}deg)`
            : "";
         const scaleTransform = enableScale ? `scale(${scaleRef.current})` : "";
         cardRef.current.style.transform = `perspective(1000px) ${rotationTransform} ${scaleTransform}`;
      }
   }, [enableRotation, enableScale]);

   const shouldShowLoadingSpinner = () => {
      if (videoSrc && videoLoaded) {
         return false;
      }
      if (imageSrc && !isImageLoaded) {
         return true;
      }
      return false;
   };

   const resetTransform = useCallback(() => {
      rotateRef.current = { x: 0, y: 0 };
      scaleRef.current = 1;
      updateTransform();
   }, [updateTransform]);

   useEffect(() => {
      if (enableRotation) {
         const checkMousePosition = setInterval(() => {
            if (
               !isMouseOver &&
               (rotateRef.current.x !== 0 ||
                  rotateRef.current.y !== 0 ||
                  scaleRef.current !== 1)
            ) {
               resetTransform();
            }
         }, 150);

         return () => clearInterval(checkMousePosition);
      }
   }, [isMouseOver, enableRotation, resetTransform]);

   const prevCardRef = useRef();
   useEffect(() => {
      if (
         innerRefCallback &&
         cardRef.current &&
         cardRef.current !== prevCardRef.current
      ) {
         innerRefCallback(cardRef);
         prevCardRef.current = cardRef.current;
      }
   }, [innerRefCallback, cardRef]);

   useEffect(() => {
      setIsImageLoaded(false); // Reset image loading state
      if (videoSrc) {
         const video = document.createElement("video");
         video.src = videoSrc;
         video.onloadeddata = () => {
            setVideoLoaded(true);
         };
         video.load();
      }

      if (imageSrc) {
         const image = new Image();
         image.src = imageSrc;
         image.onload = () => {
            setIsImageLoaded(true);
         };
      }
   }, [videoSrc, imageSrc]);

   const throttledMouseMove = useRef(
      throttle((e) => {
         if (cardRef.current && enableRotation) {
            const rect = cardRef.current.getBoundingClientRect();
            const x = e.clientX - rect.left - rect.width / 2;
            const y = e.clientY - rect.top - rect.height / 2;
            rotateRef.current.x = y / 10;
            rotateRef.current.y = -x / 10;
            updateTransform();
         }
      }, 50)
   ).current;

   const handleMouseMove = (e) => {
      throttledMouseMove(e);
   };

   const handleMouseEnter = () => {
      setIsMouseOver(true);
      if (enableScale) {
         scaleRef.current = scaleOnMouseEnter;
         updateTransform();
      }
      if (onHover) {
         onHover(); // Assuming onHover does not need an ID directly
      }
   };

   const handleMouseLeave = () => {
      setIsMouseOver(false);
      resetTransform();
      if (onMouseLeave) {
         onMouseLeave();
      }
   };

   return (
      <CardContainer onClick={onClick} $margin={margin}>
         {shouldShowLoadingSpinner() && <LoadingSpinner />}
         <StyledCard
            ref={cardRef}
            onMouseMove={enableRotation ? handleMouseMove : null}
            onMouseEnter={enableScale ? handleMouseEnter : null}
            onMouseLeave={
               enableScale || enableRotation ? handleMouseLeave : null
            }
            sizes={sizes}
            breakpoints={breakpoints}
         >
            {textBandTop && <TextBandTop>{textBandTop}</TextBandTop>}
            {children}
            {videoLoaded && videoSrc && (
               <StyledVideo
                  ref={videoRef}
                  autoPlay
                  muted
                  playsInline
                  src={videoSrc}
               />
            )}
            {imageSrc && !videoSrc && (
               <StyledImage
                  src={imageSrc}
                  alt="Card Image"
                  onLoad={() => setIsImageLoaded(true)}
                  draggable="false"

               />
            )}
            {textBandBottom && (
               <TextBandBottom>{textBandBottom}</TextBandBottom>
            )}
         </StyledCard>
      </CardContainer>
   );
};

const CardContainer = styled.div`
   position: relative;
   display: inline-block;
   margin: ${(props) => props.$margin};
`;

const StyledCard = styled.div`
   border-radius: 5px;
   overflow: hidden;
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   transition: transform 0.8s cubic-bezier(0.2, 0.8, 0.2, 1), box-shadow 0.3s,
      filter 0.3s;
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
   border: 1px solid rgba(255, 255, 255, 0.3);
   filter: brightness(1);

   &:hover {
      filter: brightness(1.05);
      box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1),
         0 0 10px 3px rgba(255, 255, 255, 0.2);
   }

   ${(props) => css`
      width: ${props.sizes.small.width};
      height: ${props.sizes.small.height};

      @media (min-width: ${props.breakpoints.small
         .width}) and (min-height: ${props.breakpoints.small.height}) {
         width: ${props.sizes.small.width};
         height: ${props.sizes.small.height};
      }

      @media (min-width: ${props.breakpoints.medium
         .width}) and (min-height: ${props.breakpoints.medium.height}) {
         width: ${props.sizes.medium.width};
         height: ${props.sizes.medium.height};
      }

      @media (min-width: ${props.breakpoints.large
         .width}) and (min-height: ${props.breakpoints.large.height}) {
         width: ${props.sizes.large.width};
         height: ${props.sizes.large.height};
      }

      @media (min-width: ${props.breakpoints.xLarge
         .width}) and (min-height: ${props.breakpoints.xLarge.height}) {
         width: ${props.sizes.xLarge.width};
         height: ${props.sizes.xLarge.height};
      }

      @media (min-width: ${props.breakpoints.xxLarge
         .width}) and (min-height: ${props.breakpoints.xxLarge.height}) {
         width: ${props.sizes.xxLarge.width};
         height: ${props.sizes.xxLarge.height};
      }
   `}
`;
const TextBandTop = styled.div`
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   color: #fbfbfb;
   text-align: center;
   padding: 5px;
   font-size: 18px;
`;
const StyledVideo = styled.video`
   width: 100%;
   height: 100%;
   object-fit: cover;
   transform: translate3d(0, 0, 0); // force hardware acceleration
`;

const StyledImage = styled.img`
   width: 100%;
   height: 100%;
   object-fit: cover;
`;

const TextBandBottom = styled.div`
   position: absolute;
   bottom: 0;
   left: 0;
   right: 0;
   background: rgba(0, 0, 0, 0.9);
   color: #fbfbfb;
   text-align: center;
   padding: 5px;
   font-size: 16px;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoadingSpinner = styled.div`
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: rgba(
      255,
      255,
      255,
      0.7
   ); // Semi-transparent white background
   z-index: 2; // Ensure it's above other elements

   &:before {
      content: "";
      border: 5px solid rgba(0, 0, 0, 0.1);
      border-top: 5px solid black;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      animation: ${rotate} 2s linear infinite;
   }
`;

export default React.memo(CardComponent);
