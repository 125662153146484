import { useEffect, useState } from "react";

/**
 * Function to fetch owned tokens, can be called independently.
 * @param {string} account - The account address.
 * @param {object} contract - The web3js contract instance.
 * @param {function} callback - A callback function to handle the result.
 */
export const fetchOwnedTokens = async (account, contract, callback) => {
   try {
      if (account && contract) {
         const totalSupplyBigInt = await contract.methods.totalSupply().call();
         const totalSupply = Number(totalSupplyBigInt);

         if (totalSupply === 0) {
            if (typeof callback === "function") {
               callback([]);
            }
            return [];
         }

         let ownedTokenIds = [];
         const batchSize = 1000; // Adjust batch size as needed

         for (let i = 0; i < totalSupply; i += batchSize) {
            // Start from 0 if zero-based
            const upperLimit = i + batchSize;

            const batchOwnedTokenIds = await contract.methods
               .tokensOfOwnerIn(account, i, upperLimit)
               .call();

            ownedTokenIds = [
               ...ownedTokenIds,
               ...batchOwnedTokenIds.map((id) => Number(id)),
            ];
         }

         if (typeof callback === "function") {
            callback(ownedTokenIds);
         }
         return ownedTokenIds;
      } else {
         if (typeof callback === "function") {
            callback([]);
         }
         return [];
      }
   } catch (error) {
      console.error("An error occurred while fetching owned tokens:", error);
      if (typeof callback === "function") {
         callback([]);
      }
      return [];
   }
};

/**
 * React hook to fetch and return owned tokens.
 * @param {string} account - The account address.
 * @param {object} contract - The web3js contract instance.
 */
const useTokensOfOwner = (account, contract) => {
   const [tokenIds, setTokenIds] = useState([]);

   useEffect(() => {
      fetchOwnedTokens(account, contract, setTokenIds);
   }, [account, contract]);

   return tokenIds;
};

export default useTokensOfOwner;
