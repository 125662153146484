import React, { useState } from 'react';
import './HomepageFAQ.scss';

const faqData = [
    {
        id: 'faq1',
        question: 'WHAT IS MAGE GAME?',
        answer: 'Mage Game is the premier brand for a collection of immersive Web3 games, set in a meticulously crafted universe where magic reigns supreme. Our platform brings together engaging storytelling, cutting-edge blockchain technology, and interactive gameplay to create a unique gaming experience. Players embark on mystical adventures, harnessing the powers of ancient magics and competing in epic battles to shape the destiny of the Mage Game world.'
    },
    {
        id: 'faq2',
        question: 'What makes Mage Game different?',
        answer: 'Mage Game stands apart by seamlessly integrating the exciting possibilities of Web3 technology with deeply engaging game narratives and mechanics. Our difference lies in our commitment to not just game development, but to forging a vibrant community of players, creators, and enthusiasts. With a focus on player agency, our games offer unparalleled interactivity, allowing players to truly influence the game world. Through decentralized assets, true ownership, and innovative gameplay, Mage Game is setting new standards in the gaming industry.'
    },
    {
        id: 'faq4',
        question: "What's next?",
        answer: 'The horizon is bright for Mage Game. Our roadmap is filled with ambitious expansions, including new game titles, major updates to existing games, and community events that bring our players together in exciting ways. We are constantly exploring the frontier of Web3 gaming, developing technologies that enhance player experience and engagement. Stay tuned for groundbreaking features, collaborative projects with leading names in the industry, and community-driven initiatives that will redefine what you expect from gaming.'
    },
];


const FAQItem = ({ faq, isOpen, onClick }) => (
    <div className={`FAQ-Item ${isOpen ? 'expanded' : ''}`}>
        <div className="FAQ-Question" onClick={onClick}>
            {faq.question}
            <span className={`arrow ${isOpen ? 'down' : 'right'}`} />
        </div>
        <div className={`FAQ-Answer ${isOpen ? 'expanded' : ''}`}>
            {faq.answer}
        </div>
    </div>
);

const HomepageFAQ = () => {
    const [openFAQs, setOpenFAQs] = useState([]);

    const toggleFAQ = (id) => {
        setOpenFAQs(openFAQs.includes(id)
            ? openFAQs.filter(faqId => faqId !== id)
            : [...openFAQs, id]);
    };

    return (
        <div className="FAQ-Container">
            {faqData.map((faq) => (
                <FAQItem
                    key={faq.id}
                    faq={faq}
                    isOpen={openFAQs.includes(faq.id)}
                    onClick={() => toggleFAQ(faq.id)}
                />
            ))}
        </div>
    );
};

export default HomepageFAQ;
