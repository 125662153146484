import "./HomepageLogo.scss";

const HomepageLogoSVG = () => {


    return (
        <svg className="Homepage-Logo-SVG" data-name="SVG-01 Image" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 130">

            <path fill="white" class="cls-1" d="M24.85,80.5c-.06,2.3-.17,4.59-.35,6.83h7.06v7.36h-12.37c-.9,0-1.8-.1-2.68-.3h0c-.66-.15-1.3-.35-1.93-.6-6.32-2.55-9.38-9.75-6.83-16.07,1.13-2.8,3.25-5.1,5.96-6.44.01-.28.03-.57.05-.86.07-1.14.16-2.3.26-3.46s.19-2.2.27-3.2c-10.26,2.72-16.36,13.25-13.64,23.5,1.91,7.2,7.82,12.65,15.15,13.97.63.11,1.27.19,1.91.24h0c.14.67.27,1.29.4,1.85.61,2.69,1.08,4.19,1.08,4.19,0,0,.46-1.48,1.07-4.15.12-.56.25-1.17.4-1.83h17.74v-21.08h-13.58v.04Z" />
            <path fill="white" class="cls-1" d="M31.56,35.51l-8.03,6.18-1.85,1.43-1.5,1.15-.98.74-.96-.74h0l-1.5-1.14h0l-1.86-1.43-8.03-6.17L0,30.24v41.01c1.7-2.94,4.04-5.45,6.86-7.34v-19.73l6.75,5.15,1.33,1.03,1.8,1.41,1.67,1.29.81.61.8-.62h0l1.67-1.29h0l1.8-1.4,1.33-1.03,6.75-5.15v18.94h-7.5c.08,1.09.17,2.28.31,3.83.1,1.09.17,2.05.23,2.91v.11h13.82V30.24l-6.87,5.27Z" />
            <path fill="white" class="cls-1" d="M15.82,4.02c0,.1.03.19.05.29,0,.01,0,.03,0,.04.02.08.04.17.06.25,0,.03.01.06.03.09.03.09.06.17.09.25.01.03.03.06.04.1l.08.16s.04.07.06.11c.03.06.06.12.1.17.04.06.07.1.11.16s.04.05.06.08c.12.15.25.3.39.43,0,0,.01.02.02.02.16.14.32.27.5.38h0s-.62,10.34-.66,18.07h4.9c-.04-7.74-.66-18.07-.66-18.07h0c.18-.11.34-.24.5-.38,0,0,.02-.01.02-.02.14-.13.27-.27.39-.43l.06-.08.11-.16c.04-.06.07-.11.1-.17.02-.04.04-.08.06-.11.03-.06.06-.11.08-.16l.04-.1c.04-.08.07-.17.09-.25,0-.03.02-.06.03-.09.03-.08.05-.16.06-.25,0-.01,0-.03,0-.04.02-.1.04-.19.05-.29v-.07c.02-.1.03-.2.04-.31.12-1.89-1.32-3.51-3.2-3.63-1.89-.12-3.51,1.32-3.63,3.2,0,.14,0,.28,0,.43,0,.1,0,.21.01.31,0,.02,0,.04,0,.07ZM19.21,1.52c1.16,0,2.11.93,2.12,2.09,0,1.16-.93,2.11-2.09,2.12-1.16,0-2.11-.93-2.12-2.09,0,0,0,0,0,0,0-1.16.93-2.1,2.09-2.11Z" />
            <path fill="white" class="cls-1" d="M7.07,28.33s7.14-.47,12.14,1.76c5-2.24,12.15-1.76,12.15-1.76.76,1.74,3.3.99,3.37-.88s-1.64-1.96-1.64-1.96H5.35s-1.71.09-1.64,1.96,2.61,2.61,3.37.88Z" />
            <path fill="white" class="cls-1" d="M16.69,40.75l1.52,1.17-.21-11.59c-.69-.25-1.4-.45-2.13-.6,0,0,.57,3.96.82,11.02Z" />
            <path fill="white" class="cls-1" d="M19.09,89.15l-.19-10.51-.13-6.82-.04-1.83-.13-6.86-.03-1.82-.11-5.91-1.78-1.37c-.08,2.35-.19,4.84-.38,7.47-.04.62-.09,1.24-.14,1.87-.09,1.23-.19,2.48-.31,3.75-.1,1.15-.19,2.29-.26,3.41-.04.66-.08,1.31-.1,1.96-.3,6.66-.04,13.33.76,19.94.94.28,1.91.42,2.89.42l-.06-3.7Z" />
            <path fill="white" class="cls-1" d="M21.75,40.76c.25-7.07.83-11.02.83-11.02-.72.15-1.43.35-2.13.6l-.22,11.59,1.52-1.17Z" />
            <path fill="white" class="cls-1" d="M22.52,89.15c.06-.6.11-1.2.16-1.83.17-2.16.3-4.45.35-6.86,0-.6.02-1.21.02-1.83.02-2.2-.03-4.48-.15-6.83-.03-.6-.06-1.21-.1-1.83-.06-.94-.14-1.9-.22-2.86-.12-1.36-.24-2.69-.33-4-.05-.62-.09-1.22-.13-1.83-.17-2.56-.28-4.98-.36-7.27l-1.78,1.38-.11,5.9-.04,1.83-.13,6.86-.03,1.83-.13,6.83-.03,1.83-.13,6.86-.04,1.83-.07,3.7h2.84c.15-1.18.28-2.42.41-3.71Z" />
            <path fill="white" class="cls-1" d="M119.34,76.58c1.14-.68,2.45-1.04,3.78-1.04,2.1,0,4.1.88,5.52,2.42l.16.17,1.24-1.17-.15-.16c-.87-.94-1.91-1.69-3.08-2.21-2.69-1.2-5.8-1.02-8.35.47-1.4.82-2.56,1.99-3.37,3.39-1.66,2.9-1.66,6.46,0,9.35.81,1.4,1.97,2.57,3.37,3.38,1.42.83,3.03,1.26,4.68,1.25h6.69v-9.35h-7.66v1.7h5.91v5.97h-4.97c-1.33,0-2.64-.35-3.78-1.04-1.15-.67-2.1-1.63-2.76-2.78-.68-1.15-1.04-2.47-1.04-3.81,0-1.34.36-2.65,1.04-3.79.67-1.14,1.62-2.09,2.76-2.74Z" />
            <path fill="white" class="cls-1" d="M154.43,92.45h1.91l2.13-4.56h9.27l2.02,4.56h1.84l-8.31-18.96-8.85,18.96ZM163.18,77.65l3.77,8.54h-7.72l3.95-8.54Z" />
            <polygon fill="white" class="cls-1" points="195.93 73.33 195.93 92.45 197.63 92.45 197.63 78.7 205.47 89.77 213.2 78.74 213.2 92.45 214.89 92.45 214.89 73.32 205.47 86.8 195.93 73.33" />
            <polygon fill="white" class="cls-1" points="252.86 75.76 252.86 74.03 240.78 74.03 240.78 92.45 252.86 92.45 252.86 90.7 242.47 90.7 242.47 84.1 250.57 84.1 250.57 82.38 242.47 82.38 242.47 75.76 252.86 75.76" />
            <polygon fill="white" class="cls-1" points="73.55 23.36 73.55 57.29 78.09 57.29 78.09 37.69 89.48 53.76 100.68 37.78 100.68 57.29 105.22 57.29 105.22 23.32 89.46 45.84 73.55 23.36" />
            <path fill="white" class="cls-1" d="M220.9,32.23c4.41-2.55,10-1.71,13.46,2.02l.89.95,3.31-3.12-.87-.94c-1.44-1.57-3.19-2.82-5.13-3.69-4.51-2.02-9.72-1.73-13.97.78-2.33,1.37-4.27,3.33-5.62,5.67-2.78,4.84-2.78,10.79,0,15.63,1.35,2.34,3.3,4.29,5.63,5.65,2.36,1.39,5.05,2.11,7.79,2.09h11.5v-16.61h-13.94v4.54h9.3v7.53h-6.88c-1.92.01-3.81-.5-5.46-1.48-1.66-.98-3.04-2.37-4.01-4.03-.98-1.67-1.49-3.57-1.48-5.51-.01-1.93.5-3.83,1.48-5.49.97-1.65,2.35-3.03,4.01-3.99Z" />
            <rect fill="white" class="cls-1" x="280.08" y="26.48" width="20.86" height="4.58" />
            <rect fill="white" class="cls-1" x="280.08" y="39.59" width="20.86" height="4.58" />
            <rect fill="white" class="cls-1" x="280.08" y="52.7" width="20.86" height="4.58" />
            <path fill="white" class="cls-1" d="M144.53,57.28h29.85l-14.5-33.01-15.35,33.01ZM151.67,52.74l8.07-17.36,7.68,17.36h-15.75Z" />
        </svg>
    );
};

export default HomepageLogoSVG