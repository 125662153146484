const reportWebVitals = (onPerfEntry) => {
   if (onPerfEntry && onPerfEntry instanceof Function) {
      import("web-vitals")
         .then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
            getCLS(onPerfEntry);
            getFID(onPerfEntry);
            getFCP(onPerfEntry);
            getLCP(onPerfEntry);
            getTTFB(onPerfEntry);
            return null; // Added to satisfy eslint-plugin-promise
         })
         .catch((error) => {
            // Handle or log the error
            console.error("Error with web vitals:", error);
         });
   }
};

export default reportWebVitals;
