// AnimationContext.js
import React, { createContext, useState } from "react";

export const AnimationContext = createContext();

export const AnimationProvider = ({ children }) => {
   const [controls, setControls] = useState();
   const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);

   const provideAnimationControls = (animationControls) => {
      setControls(animationControls);
   };

   const toggleSidebar = () => {
      setIsSidebarExpanded(!isSidebarExpanded);
   };

   return (
      <AnimationContext.Provider
         value={{
            controls,
            provideAnimationControls,
            isSidebarExpanded,
            setIsSidebarExpanded,
            toggleSidebar,
         }}
      >
         {children}
      </AnimationContext.Provider>
   );
};
