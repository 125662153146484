import React, { useState, useContext } from "react";
import Web3 from "web3";
import { TokenMetadataContext } from "../contexts/TokenMetadataContext";
import useApi from "./useApi";
import { contracts } from "../static/contractData";

const useClaim = (contractId, tokenIds) => {
   const [web3, setWeb3] = useState(null);
   const [signature, setSignature] = useState("");
   const [loading, setLoading] = useState(false);
   const { accountAddress, isWalletConnected } =
      useContext(TokenMetadataContext);
   const { sendRequest } = useApi();

   if (!contractId || !Array.isArray(tokenIds) || tokenIds.length === 0) {
      return { mint: () => {}, loading: false, signature: "" };
   }

   if (isWalletConnected && !web3) {
      setWeb3(new Web3(window.ethereum));
   }

   const contractDetails = contracts.find(
      (contract) => contract.id === contractId
   );

   if (!contractDetails) {
      console.error("Contract details not found for the given ID.");
      return { mint: () => {}, loading: false, signature: "" };
   }

   const claimType = contractDetails?.claimType;

   const getSignature = async () => {
      try {
         const response = await sendRequest(
            "POST",
            "http://localhost:8080/api/auth/claim-tokens",
            {
               tokenIds,
               claimType,
            }
         );
         console.log("Response from server:", response);
         const signatureResponse = response.split("Signature: ")[1];
         if (!signatureResponse) {
            console.error("Signature not found in the response");
            return "";
         }
         return signatureResponse.trim();
      } catch (error) {
         console.error("Error fetching signature: ", error);
         return "";
      }
   };

   const executeMintTransaction = async () => {
      if (!web3 || tokenIds.length === 0 || !claimType || !accountAddress) {
         console.error(
            "Web3 is not initialized, or required fields are missing."
         );
         return;
      }

      setLoading(true);
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const obtainedSignature = await getSignature();

      if (!obtainedSignature) {
         console.error("No signature obtained. Cannot proceed with minting.");
         setLoading(false);
         return;
      }

      try {
         const contract = new web3.eth.Contract(
            contractDetails.abi,
            contractDetails.address
         );
         const encodedFunctionCall = contract.methods
            .signatureMint(tokenIds, obtainedSignature)
            .encodeABI();

         console.log("Encoded function call:", encodedFunctionCall);

         const transactionParameters = {
            to: contractDetails.address,
            data: encodedFunctionCall,
            from: accountAddress,
         };

         console.log(
            "Sending transaction with parameters:",
            transactionParameters
         );
         const txHash = await web3.eth.sendTransaction(transactionParameters);
         console.log("Transaction hash:", txHash);
      } catch (error) {
         console.error("Error during minting: ", error);
      } finally {
         setLoading(false);
      }
   };

   return {
      mint: executeMintTransaction,
      loading,
      signature,
   };
};

export default useClaim;
