import { useContext } from "react";
import axios from "axios";
import { TokenMetadataContext } from "../contexts/TokenMetadataContext.js";

const useApi = () => {
   const { accountAddress } = useContext(TokenMetadataContext);

   const handleSignIn = async (messageToSign) => {
      try {
         const signedMessage = await window.ethereum.request({
            method: "personal_sign",
            params: [messageToSign, accountAddress],
         });

         const authEndpoint = "http://localhost:8080/api/authenticate";
         const requestBody = {
            account: accountAddress,
            signature: signedMessage,
         };

         const response = await axios.post(authEndpoint, requestBody, {
            headers: { "X-Ethereum-Address": accountAddress },
            withCredentials: true,
         });

         return response;
      } catch (error) {
         console.error("Error during handleSignIn:", error);
         throw error;
      }
   };
   const triggerSignInIfNeeded = async (errorResponse) => {
      // Check if the response data is a string and parse it if necessary
      let responseData = errorResponse.data;
      if (typeof responseData === "string") {
         try {
            responseData = JSON.parse(responseData);
         } catch (error) {
            console.error("Error parsing the error response data:", error);
            // Handle parsing error or rethrow it
            return;
         }
      }

      const signInRequired = responseData && responseData.signInRequired;
      if (signInRequired) {
         await handleSignIn(responseData.messageToSign);
      }
   };

   const sendRequest = async (requestType, endpoint, data = {}) => {
      const config = {
         headers: { "X-Ethereum-Address": accountAddress },
         withCredentials: true,
      };

      try {
         let response;

         if (requestType === "GET") {
            response = await axios.get(endpoint, config);
         } else if (requestType === "POST") {
            response = await axios.post(endpoint, data, config);
         }

         return response.data;
      } catch (error) {
         console.error("Error in sendRequest:", error);
         // Check if the error response is a 401 Unauthorized
         if (error.response && error.response.status === 401) {
            await triggerSignInIfNeeded(error.response);
         } else {
            // For all other errors, just throw them
            throw error;
         }
      }
   };

   return { sendRequest };
};

export default useApi;
