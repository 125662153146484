import React, { useState, useContext, useEffect } from "react";
import Web3 from "web3";
import { TokenMetadataContext } from "./contexts/TokenMetadataContext";
import { contracts } from "./static/contractData";

const SpecificClaimNavigator = ({
   specificClaims,
   handleBackButtonClick,
   handleClaimSuccess, // This callback is passed from the parent component
   itemsPerPage = 4,
   onClaim,
}) => {
   const [currentPage, setCurrentPage] = useState(1);
   const totalClaims = specificClaims.length;
   const totalPages = Math.ceil(totalClaims / itemsPerPage);
   const [loading, setLoading] = useState(false);
   const [web3, setWeb3] = useState(null);
   const { accountAddress, isWalletConnected } =
      useContext(TokenMetadataContext);

   useEffect(() => {
      if (isWalletConnected && !web3) {
         setWeb3(new Web3(window.ethereum));
      }
   }, [isWalletConnected, web3]);

   const executeMintTransaction = async (contractId, tokenIds, signature) => {
      if (!web3) {
         console.error("Web3 instance is not initialized.");
         return;
      }
      if (!contractId) {
         console.error("Contract ID is missing.");
         return;
      }
      if (!Array.isArray(tokenIds) || tokenIds.length === 0) {
         console.error("Token IDs are missing or not in an array.");
         return;
      }
      if (!signature) {
         console.error("Signature is missing.");
         return;
      }
      if (!accountAddress) {
         console.error("Account address is missing.");
         return;
      }

      setLoading(true);
      await window.ethereum.request({ method: "eth_requestAccounts" });

      const contractDetails = contracts.find(
         (contract) => contract.id === contractId
      );
      if (!contractDetails) {
         console.error(
            "Contract details not found for the given ID:",
            contractId
         );
         setLoading(false);
         return;
      }

      try {
         const contract = new web3.eth.Contract(
            contractDetails.abi,
            contractDetails.address
         );
         const encodedFunctionCall = contract.methods
            .signatureMint(tokenIds, signature)
            .encodeABI();

         const transactionParameters = {
            to: contractDetails.address,
            data: encodedFunctionCall,
            from: accountAddress,
         };

         const txHash = await web3.eth.sendTransaction(transactionParameters);
         console.log("Transaction hash:", txHash);
         // If the transaction was successful, call the handleClaimSuccess callback
         if (handleClaimSuccess && typeof handleClaimSuccess === "function") {
            handleClaimSuccess();
         }
         if (onClaim && typeof onClaim === "function") {
            onClaim();
         }
      } catch (error) {
         console.error("Error during minting:", error);
      } finally {
         setLoading(false);
      }
   };

   const handlePendingClaimsClick = (claim) => {
      if (loading) {
         console.log("Transaction is already in progress");
         return;
      }

      executeMintTransaction(
         claim.contractId,
         claim.tokenIds,
         claim.ethSignature
      );
   };

   const startIndex = (currentPage - 1) * itemsPerPage;
   const claimsToShow = specificClaims.slice(
      startIndex,
      startIndex + itemsPerPage
   );

   const renderClaimHeader = () => (
      <div className="Inventory-Item-Subheading">
         <h2>
            <span>Pending Claims</span>
            <button className="Exit-Button" onClick={handleBackButtonClick}>
               &#x2715; {/* Unicode X character */}
            </button>
         </h2>
      </div>
   );

   return (
      <>
         {renderClaimHeader()}
         <div className="Token-Selection-Container">
            <button
               className="Page-Navigation-Button"
               onClick={() => setCurrentPage(currentPage - 1)}
               disabled={currentPage === 1}
            >
               {"<"}
            </button>

            <div className="Inv-Claim-Grid">
               {claimsToShow.map((claim, index) => (
                  <button
                     key={index}
                     className="Claim-Button"
                     onClick={() => handlePendingClaimsClick(claim)}
                  >
                     {`${claim.claimType} x ${claim.tokenIds.length}`}
                  </button>
               ))}
            </div>

            <button
               className="Page-Navigation-Button"
               onClick={() => setCurrentPage(currentPage + 1)}
               disabled={currentPage === totalPages}
            >
               {">"}
            </button>
         </div>
      </>
   );
};

export default SpecificClaimNavigator;
