import React, { useState } from "react";
import "./App.scss";
import Homepage from "./Homepage.js";
import TabbedContent from "./TabbedContent";
import HoldingPage from "./HoldingPage";
import campFire from "./assets/icons/campFire.gif";
import pack from "./assets/icons/pack.png";
import genMage from "./assets/icons/genesisMageHat.png";
import duel from "./assets/icons/duel.png";
import MGLogo from "./assets/svgs/MGLogo.svg";
import Shop from "./Shop";
import "./GlobalStyles.css";
import { TokenMetadataProvider } from "./contexts/TokenMetadataContext.js";
import Inventory from "./Inventory.js";
import Placeholder from "./Placeholder.js";
function App() {
   const [showContent, setShowContent] = useState(false);
   const tabsData = [
      {
         title: "Home",
         subtitle: "",
         icon: MGLogo,
         component: Homepage,
      },
      {
         title: "Shop",
         subtitle: "",
         icon: pack,
         component: Shop,
      },
      {
         title: "Collection",
         subtitle: "",
         icon: genMage,
         component: Inventory,
      },
      {
         title: "Arena",
         subtitle: "",
         icon: duel,
         component: Placeholder,
      },
   ];

   return (
      <TokenMetadataProvider>
         <div className="App">

            <TabbedContent tabsData={tabsData} />
         </div>
      </TokenMetadataProvider>
   );
}

export default App;
