import React, { useState } from "react";
import { motion } from "framer-motion";
import CardComponent from "./CardComponent";
import "./GridImageDisplay.scss";
const GridImageDisplay = ({ selectedItem, clearSelection }) => {
   // State to hold refs
   const [cardRefs, setCardRefs] = useState({
      ref1: null,
      ref2: null,
      ref3: null,
      ref4: null,
   });

   // Callback function to update refs
   const setCardRef = (cardIndex) => (ref) => {
      setCardRefs((prevRefs) => ({ ...prevRefs, [cardIndex]: ref.current }));
   };

   // Variants for the cards
   const cardVariants = {
      out: {
         x: -100,
         opacity: 0,
         transition: { duration: 0.3 },
      },
      in: {
         x: 0,
         opacity: 1,
         transition: {
            type: "spring",
            stiffness: 500,
            damping: 20,
            mass: 1,
            when: "beforeChildren",
            staggerChildren: 0.1,
         },
      },
      exit: {
         opacity: 0,
         transition: { duration: 0 },
      },
   };

   // Event handler for clicks on the wrapper
   const handleWrapperClick = (e) => {
      if (
         !Object.values(cardRefs).some((ref) => ref && ref.contains(e.target))
      ) {
         clearSelection();
      }
   };

   const cardSizes = {
      small: { width: "190px", height: "285px" },
      medium: { width: "240px", height: "360px" },
      large: { width: "280px", height: "420px" },
      xLarge: { width: "320px", height: "480px" },
      xxLarge: { width: "350px", height: "525px" },
   };

   // Function to get quadrant data safely
   const getQuadrantData = (index) => {
      return (
         selectedItem && selectedItem.quadrants && selectedItem.quadrants[index]
      );
   };

   return (
      <motion.div
         className="Item-Image-Container-Wrapper"
         initial="out"
         animate={selectedItem ? "in" : "out"}
         exit="exit"
         variants={cardVariants}
         onClick={handleWrapperClick}
      >
         <div className="Item-Image-Container">
            {Array.from({ length: 4 }, (_, i) => (
               <motion.div
                  key={`Item-Image-Q${i + 1}`}
                  className={`Item-Image-Q${i + 1}`}
                  variants={cardVariants}
                  sizes={cardSizes}
               >
                  {" "}
                  <h2>{getQuadrantData(i)?.heading || ""}</h2>
                  <CardComponent
                     imageSrc={getQuadrantData(i)?.image || ""}
                     innerRefCallback={setCardRef(`ref${i + 1}`)}
                     sizes={cardSizes}
                  />
               </motion.div>
            ))}
         </div>
      </motion.div>
   );
};

export default GridImageDisplay;
