import React, { useState, useEffect, useContext } from "react";
import { motion, LayoutGroup } from "framer-motion";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import "./ItemsDisplaySidebar.scss";
import { AnimationContext } from "./AnimationContext";
import { v4 as uuidv4 } from "uuid";

const sidebarVariants = {
   open: {
      width: "300px",
      transition: { stiffness: 100 },
      overflow: "auto",
      background: "rgba(17, 17, 20, 0.8)",
   },
   collapsed: {
      width: "30px",
      transition: { stiffness: 100 },
      overflow: "hidden",
      background: "rgba(0, 0, 0, 0)",
   },
};

const ItemsDisplaySidebar = ({ traitTypes, filters, updateFilters }) => {
   const [highlightStyle, setHighlightStyle] = useState({});
   const [instanceId] = useState(uuidv4()); // Generate a unique ID for this instance
   const { isSidebarExpanded, setIsSidebarExpanded } =
      useContext(AnimationContext);

   useEffect(() => {
      return () => {
         // Set isSidebarExpanded to false on unmount
         setIsSidebarExpanded(false);
      };
   }, [setIsSidebarExpanded]);

   const handleToggleSidebar = () => {
      setIsSidebarExpanded(!isSidebarExpanded);
   };

   const toggleFilter = (traitType, value) => {
      const isChecked = filters[traitType]?.[value] || false;
      updateFilters(traitType, value, !isChecked);
   };

   const handleMouseEnter = (e) => {
      const { offsetTop, offsetLeft, offsetWidth, offsetHeight } =
         e.currentTarget;
      setHighlightStyle({
         top: offsetTop,
         left: offsetLeft,
         width: offsetWidth,
         height: offsetHeight,
         opacity: 1,
      });
   };

   const handleMouseLeave = () => {
      setHighlightStyle((prevStyle) => ({ ...prevStyle, opacity: 0 }));
   };

   return (
      <motion.div
         className="Item-Display-Sidebar"
         variants={sidebarVariants}
         initial={false}
         animate={!isSidebarExpanded ? "collapsed" : "open"}
      >
         <motion.button
            onClick={handleToggleSidebar}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="toggle-button"
            style={{ zIndex: 3 }}
         >
            {!isSidebarExpanded ? <FaChevronLeft /> : <FaChevronRight />}
         </motion.button>

         <LayoutGroup>
            {isSidebarExpanded && (
               <>
                  <div
                     className="Item-Display-Title"
                     style={{ alignSelf: "center" }}
                  >
                     <h1>
                        <span>Traits</span>
                     </h1>
                  </div>
                  {Object.entries(traitTypes).map(([traitType, values]) => (
                     <div key={traitType} className="trait-category">
                        <h3>
                           <span>{traitType}</span>
                        </h3>
                        {Object.entries(values)
                           .sort((b, a) => a[1] - b[1]) // Sorting by count
                           .map(([value, count]) => (
                              <div
                                 key={value}
                                 className="sidebar-item"
                                 onMouseEnter={handleMouseEnter}
                                 onMouseLeave={handleMouseLeave}
                                 onClick={() => toggleFilter(traitType, value)}
                              >
                                 <input
                                    type="checkbox"
                                    checked={
                                       filters[traitType]?.[value] || false
                                    }
                                    readOnly
                                 />
                                 <span className="label-text">{value}</span>
                                 <span className="count">{`${count}`}</span>
                              </div>
                           ))}
                     </div>
                  ))}
               </>
            )}

            <motion.div
               className="Item-Display-MotionHighlight"
               layoutId={`highlight_${instanceId}`}
               initial={false}
               animate={highlightStyle}
               transition={{ ease: "easeOut", duration: 0.3 }}
            />
         </LayoutGroup>
      </motion.div>
   );
};

export default ItemsDisplaySidebar;
