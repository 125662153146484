import Web3 from "web3";

let web3;
if (window.ethereum) {
   web3 = new Web3(window.ethereum);
}

/**
 * Check if the wallet is connected.
 * @return {Promise<boolean>} True if a wallet is connected, otherwise false.
 */
export async function isWalletConnected() {
   try {
      const accounts = await web3.eth.getAccounts();
      return accounts.length > 0;
   } catch (error) {
      console.error("Error checking wallet connection:", error);
      return false;
   }
}

/**
 * Get the connected account address.
 * @return {Promise<string|null>} The account address if connected, otherwise null.
 */
export async function getAccountAddress() {
   try {
      if (await isWalletConnected()) {
         const accounts = await web3.eth.getAccounts();
         return accounts[0];
      } else {
         return null;
      }
   } catch (error) {
      console.error("Error fetching account address:", error);
      return null;
   }
}

/**
 * Check the current chain ID against the expected chain ID.
 * @param {number} expectedChainId - The expected chain ID.
 * @return {Promise<boolean>} True if the current chain ID matches the expected one, otherwise false.
 */
export const checkChainId = async (expectedChainId) => {
   try {
      const chainId = await web3.eth.getChainId();
      return chainId === expectedChainId;
   } catch (error) {
      console.error("Error checking chain ID:", error);
      return false;
   }
};

/**
 * Connect the wallet using MetaMask.
 * @return {Promise<boolean>} True if connected successfully, otherwise false.
 */
export const connectWallet = async () => {
   try {
      await window.ethereum.request({ method: "eth_requestAccounts" });
      return true;
   } catch (error) {
      console.error("Error connecting MetaMask:", error);
      return false;
   }
};
