import React from 'react';
import './TraitsBox.scss';

// Modify the TraitsBox component to accept an additional prop for handling clicks
const TraitsBox = ({ traits, onTraitClick }) => {
    const traitsLength = traits?.length ?? 0;

    return (
        <div>
            <div className="TraitsBox-Title"><span>ATTRIBUTES [{traitsLength}]</span></div>
            <div className="TraitsBox-Traitboxes-Container">
                {traits?.map((trait, index) => (
                    <div key={index} className={`TraitBox ${trait.isActive ? 'active' : ''}`} onClick={() => onTraitClick(trait)}>
                        <div className="TraitBox-Type"><span>{trait.trait_type}</span></div>
                        <div className="TraitBox-Value">{trait.value}</div>
                        <div className="TraitBox-Details">
                            <span className="TraitBox-Percentage">{trait.percentage}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};


export default TraitsBox;
