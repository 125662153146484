import { motion } from "framer-motion";
import "./ListWithMotion.scss";

// Variants for the items with a more dynamic spring transition
const itemVariants = {
   out: {
      x: 100,
      opacity: 0,
   },
   in: {
      x: 0,
      opacity: 1,
      transition: {
         type: "spring",
         stiffness: 500,
         damping: 20,
         mass: 1,
         when: "beforeChildren",
         staggerChildren: 0.3,
      },
   },
};

// The ListWithMotion component
const ListWithMotion = ({ items, quantity }) => {
   return (
      <div className="LWM-OverflowWrapper">
         <motion.div
            className="LWM-ListContainer"
            initial="out"
            animate="in"
            variants={itemVariants}
         >
            {items.map((item, index) => (
               <motion.div
                  key={index}
                  className="LWM-ListItem"
                  variants={itemVariants}
               >
                  <img src={item.icon} alt={item.text} className="LWM-Icon" />
                  <span className="LWM-Text">{item.text}</span>
                  {quantity && item.quantity && (
                     <span className="LWM-Quantity">{` x ${quantity}`}</span>
                  )}
               </motion.div>
            ))}
         </motion.div>
      </div>
   );
};

export default ListWithMotion;
