// Placeholder.jsx

import React from 'react';
import './Placeholder.scss'; // Assuming CSS Modules

const Placeholder = () => {
    return (
        <div className="Placeholder-text">
            Coming Soon
        </div>
    );
};

export default Placeholder;
