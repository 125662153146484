import React, { useEffect } from "react";
import { motion, useSpring, useTransform } from "framer-motion";
import "./Counter.scss"; // Import the new stylesheet

function Counter({ value, maxLength }) {
   const digits = value.toString().padStart(maxLength, "0").split("");

   return (
      <div className="Counter-container">
         {digits.map((digit, index) => (
            <Digit
               key={index}
               place={Math.pow(10, maxLength - index - 1)}
               value={parseInt(digit, 10)}
            />
         ))}
      </div>
   );
}

function Digit({ place, value }) {
   let animatedValue = useSpring(value);

   useEffect(() => {
      animatedValue.set(value);
   }, [animatedValue, value]);

   return (
      <div className="Counter-digit">
         {[...Array(10).keys()].map((i) => (
            <Number key={i} mv={animatedValue} number={i} />
         ))}
      </div>
   );
}

function Number({ mv, number }) {
   const height = 45; // Base height for larger screens
   let y = useTransform(mv, (latest) => (number - latest) * height);

   return (
      <motion.span style={{ y }} className="Counter-number">
         {number}
      </motion.span>
   );
}

export default Counter;
