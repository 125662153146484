import React, { useState, useEffect } from "react";
import useWeb3 from "./hooks/useWeb3";
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ShopItemInfoAndPurchase.scss";
import CardComponent from "./CardComponent";
import Counter from "./Counter";
import ListWithMotion from "./ListWithMotion";
import Web3 from "web3";
import SlideButton from "./SlideButton";

const ShopItemInfoAndPurchase = ({ selectedItem }) => {
   const [quantity, setQuantity] = useState(1);
   const [maxSupply, setMaxSupply] = useState(11011);
   const [maxMintQuantity, setMaxMintQuantity] = useState(10);
   const [totalMinted, setTotalMinted] = useState(0);
   const availableSupply = maxSupply - totalMinted;
   const isSoldOut = availableSupply === 0; // Determine if the item is sold out

   useEffect(() => {
      if (isSoldOut) {
         setQuantity(1); // Reset quantity to 1 if sold out
      }
   }, [isSoldOut]);
   const {
      web3,
      isWalletConnected,
      contractInstance,
      connectWallet,
      checkChainId,
   } = useWeb3(
      selectedItem.contractABI,
      selectedItem.contractAddress,
      selectedItem.chainId
   );

   // Fetching contract data
   const fetchContractData = async () => {
      if (!contractInstance) return;

      try {
         const [supply, mintedCount, maxMint] = await Promise.all([
            contractInstance.methods.getMaxSupply().call(),
            contractInstance.methods.totalSupply().call(),
            contractInstance.methods.getMaxMintQuantity().call(),
         ]);
         setMaxSupply(parseInt(supply));
         setTotalMinted(parseInt(mintedCount));
         setMaxMintQuantity(parseInt(maxMint));
      } catch (error) {
         console.error("Error fetching contract data:", error);
         toast.error("Error fetching contract data.");
      }
   };

   useEffect(() => {
      if (isWalletConnected) {
         fetchContractData();
      }
   }, [isWalletConnected, contractInstance]);

   const calculateCostInWei = () => {
      const priceInWei = Web3.utils.toWei(
         selectedItem.price.toString(),
         "ether"
      );
      return parseInt(priceInWei) * quantity;
   };

   const handleMint = async () => {
      if (!isWalletConnected) {
         await connectWallet();
         return;
      }

      await checkChainId();

      const totalCostInWei = calculateCostInWei();
      if (totalMinted + quantity > maxSupply) {
         toast.error("Minting quantity exceeds available supply.");
         return;
      }

      if (quantity > maxMintQuantity) {
         toast.error(`Maximum mint quantity is ${maxMintQuantity}.`);
         return;
      }

      try {
         const accounts = await web3.eth.getAccounts();
         const receipt = await contractInstance.methods.mint(quantity).send({
            from: accounts[0],
            value: totalCostInWei.toString(),
         });
         console.log("Minting transaction receipt:", receipt);
         toast.success("Minting successful!");
         fetchContractData(); // Refresh the contract data
      } catch (error) {
         console.error("Error during minting:", error);
         toast.error("Minting failed.");
      }
   };

   const handleQuantityChange = (event) => {
      let newQuantity = parseInt(event.target.value);
      if (isNaN(newQuantity) || newQuantity < 1) {
         newQuantity = 1;
      } else if (newQuantity > Math.min(maxMintQuantity, availableSupply)) {
         newQuantity = Math.min(maxMintQuantity, availableSupply);
      }
      setQuantity(newQuantity);
   };

   const incrementQuantity = () => {
      setQuantity((prevQuantity) =>
         Math.min(prevQuantity + 1, Math.min(maxMintQuantity, availableSupply))
      );
   };

   const decrementQuantity = () => {
      setQuantity((prevQuantity) => Math.max(prevQuantity - 1, 1));
   };

   const formatPrice = (price) => {
      return `${price.toFixed(2)} Ξ`;
   };


   // Framer Motion animation settings for fade-in
   const fadeIn = {
      hidden: { opacity: 0 },
      visible: { opacity: 1 },
   };

   return (
      <>
         <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
         />
         <motion.div className="Item-Info-Container">
            <div className="Item-Info-Heading">
               <h1>
                  <span>{selectedItem.title}</span>
               </h1>
            </div>
            <div className="Item-Info-Card">
               <CardComponent
                  imageSrc={selectedItem.image}
                  scaleOnMouseEnter={1.1}
                  width="270px"
                  height="420px"
               />
            </div>
            <motion.div
               className="Item-Info-Mint"
               initial="hidden"
               animate="visible"
               variants={fadeIn}
               transition={{ duration: 1.5}} // Customize the duration as needed
            >               {isSoldOut ? (
                  <h2 className="sold-out-message">Sold Out</h2>
               ) : isWalletConnected ? (
                  <>
                     <div
                        className="number-minted"
                        style={{ display: "flex", alignItems: "center" }}
                     >
                        <Counter value={Number(totalMinted)} />
                        <span style={{ color: "white", margin: "0 10px" }}>
                           /
                        </span>
                        <Counter value={maxSupply} />
                     </div>
                     <div className="quantity-selector">
                        <button
                           onClick={decrementQuantity}
                           className="quantity-btn"
                           disabled={quantity <= 1}
                        >
                           -
                        </button>
                        <input
                           className="quantity-input"
                           type="text"
                           value={quantity}
                           onChange={handleQuantityChange}
                           disabled={availableSupply <= 0}
                        />
                        <button
                           onClick={incrementQuantity}
                           className="quantity-btn"
                           disabled={quantity >= availableSupply}
                        >
                           +
                        </button>
                     </div>
                        <SlideButton 
        text={`Mint ${formatPrice(selectedItem.price * quantity)}`} 
        onClick={handleMint} 
      />
                     
                  </>
               ) : (
                  <button onClick={connectWallet} className="mint-button">
                     Connect
                  </button>
               )}
            </motion.div>
            <div className="Item-Info-Bulletpoints">
               <ListWithMotion
                  items={selectedItem.bulletPoints}
                  quantity={quantity}
               />
            </div>
            <div className="Item-Info-Footer"></div>
         </motion.div>
      </>
   );
};

export default ShopItemInfoAndPurchase;
