import React from 'react';
import './SlideButton.scss'; // Import SCSS file

const SlideButton = ({ text, onClick }) => {
  return (
    <button className="slide-button" onClick={onClick}>
      <span>
        <span className="container">
          <span className="primary"></span>
          <span className="complimentary"></span>
        </span>
      </span>
      <span>{text}</span>
    </button>
  );
};

export default SlideButton;
