import Dexie from "dexie";
import * as yup from "yup";

const TokenMetadataDB = new Dexie("TokenMetadataDB");

// Updated database schema to include attributes in tokens
TokenMetadataDB.version(1).stores({
  tokens:
    "[contractAddress+id], collection, name, image, external_url, attributes",
});

const urlRegex = /^(http|https):\/\/[^ "]+$/;
const customUrlValidator = yup
  .string()
  .matches(urlRegex, "Must be a valid URL");

// Updated Yup schema for validation including attributes
const tokenSchema = yup.object().shape({
  contractAddress: yup.string().required(),
  id: yup.string().required(),
  collection: yup.string().required(),
  name: yup.string().required(),
  image: customUrlValidator.required(),
  external_url: customUrlValidator.required(),
  attributes: yup
    .array()
    .of(
      yup.object().shape({
        trait_type: yup.string().required(),
        value: yup.mixed().required(),
      })
    )
    .required(),
});

// Updated function to add token metadata including attributes
export const addTokenMetadata = async (metadata) => {
  try {
    await tokenSchema.validate(metadata);

    // Directly add the entire metadata object to the tokens store
    await TokenMetadataDB.tokens.add(metadata);

    console.log(
      "Token metadata added successfully:",
      `${metadata.contractAddress}-${metadata.id}`
    );
  } catch (error) {
    console.error("Failed to add token metadata:", error);
    throw error;
  }
};

// Function to get token metadata by contractAddress and id
export const getTokenMetadata = async (contractAddress, id) => {
  return await TokenMetadataDB.tokens.get({ contractAddress, id });
};

// Function to get all token metadata
export const getAllTokenMetadata = async () => {
  return await TokenMetadataDB.tokens.toArray();
};

export default TokenMetadataDB;
