import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { v4 as uuidv4 } from "uuid";
import "./InventoryTabs.scss";

const InventoryTabs = ({ contracts, onContractSelect }) => {
   const [focused, setFocused] = useState(null);
   const [selected, setSelected] = useState(null);
   const [instanceId] = useState(uuidv4()); // Generate a unique ID for this instance

   const handleSelect = (contract) => {
      setSelected(contract.id);
      onContractSelect(contract);
   };

   useEffect(() => {
      if (contracts.length > 0 && selected === null) {
         const firstContract = contracts[0];
         setSelected(firstContract.id);
         onContractSelect(firstContract);
      }
   }, [contracts, selected, onContractSelect]);

   return (
      <ul
         className="Inventory-Tabs-Wrapper"
         onMouseLeave={() => setFocused(null)}
      >
         {contracts.map((contract) => (
            <li
               key={contract.id}
               className="Inventory-Tabs-Tab"
               onClick={() => handleSelect(contract)}
               onFocus={() => setFocused(contract.id)}
               onMouseEnter={() => setFocused(contract.id)}
               tabIndex={0}
            >
               <span className="Inventory-Tabs-Span">{contract.name}</span>
               {focused === contract.id && (
                  <motion.div
                     transition={{ layout: { duration: 0.2, ease: "easeOut" } }}
                     className="Inventory-Tabs-MotionHighlight"
                     layoutId={`highlight_${instanceId}`}
                  />
               )}
               {selected === contract.id && (
                  <motion.div
                     className="Inventory-Tabs-SelectedIndicator"
                     layoutId={`underline_${instanceId}`}
                  />
               )}
            </li>
         ))}
      </ul>
   );
};

export default InventoryTabs;
