import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import GlassHeader from "./GlassHeader";
import "./TabbedContent.scss";
import { AnimationProvider } from "./AnimationContext";

const TabbedContent = ({ tabsData }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [pageDirection, setPageDirection] = useState(1);

  const getPageTransition = () => ({
    initial: {
      x: pageDirection > 0 ? "100%" : "-100%",
      opacity: 0
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 260, damping: 20 }
    },
    exit: {
      x: pageDirection > 0 ? "-100%" : "100%",
      opacity: 0,
      transition: { duration: 0.2 } // Shorten exit transition to make it feel more responsive
    }
  });

  const paginate = (newIndex) => {
    setPageDirection(newIndex > activeTab ? 1 : -1);
    setActiveTab(newIndex); // Update active tab immediately
  };

  const renderActiveComponent = () => {
    const ActiveComponent = tabsData[activeTab]?.component;
    return <ActiveComponent />;
  };

  return (
    <AnimationProvider>
      <div className="TC-Container">
        <GlassHeader
          tabsData={tabsData}
          paginate={paginate}
          activeTab={activeTab}
        />
        <div className="TC-Body">
          <AnimatePresence
            mode="wait" // This ensures only one component is animated at a time, improving responsiveness
            initial={false} // Prevent initial animation on first render
          >
            <motion.div
              key={activeTab}
              variants={getPageTransition()}
              initial="initial"
              animate="animate"
              exit="exit"
              style={{
                height: "100%",
                width: "100%",
                position: "absolute"
              }}
            >
              {renderActiveComponent()}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </AnimationProvider>
  );
};

export default TabbedContent;
