// GlassTab.js
import React from "react";
import PropTypes from "prop-types";
import "./GlassTab.scss";

const GlassTab = ({ title, subtitle, icon, isActive, onClick, showText, isFirstTab }) => {
  const tabClass = `GlassTab ${isActive ? "active" : ""} ${!showText ? "compressed" : ""}`;
  // Apply specific classes for the first tab and when it's active
  const iconClass = `GlassTab-icon ${isFirstTab ? "first-tab-icon" : ""} ${isActive && isFirstTab ? "active-first-tab-icon" : ""}`;

  return (
    <div className={tabClass} onClick={onClick}>
      <img src={icon} alt={`${title} icon`} className={iconClass} />
      {showText && (
        <div className="GlassTab-text">
          <span className="GlassTab-title">{title}</span>
          {subtitle && <span className="GlassTab-subtitle">{subtitle}</span>}
        </div>
      )}
    </div>
  );
};

GlassTab.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  showText: PropTypes.bool.isRequired,
  isFirstTab: PropTypes.bool // New prop for identifying the first tab
};

export default GlassTab;
